import { Routes } from '@angular/router';

export const EditorRouting: Routes = [
    {
        path: 'editor',
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('./pages/editor/editor.page.js').then(m => m.EditorPage),
                data: {
                    reuseComponent: true,
                },
            },
        ]
    },
    {
        path: '',
        redirectTo: 'editor',
        pathMatch: 'full',
    },
];
