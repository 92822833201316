import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AppTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    let titleName = this.buildTitle(routerState);
    if (titleName !== undefined) {
      titleName = `${titleName} | app`;
      this.title.setTitle(titleName);
    } else {
      // set default title here
    }
  }
}
