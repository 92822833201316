import {
  Component,
  ViewEncapsulation,
  Pipe,
  PipeTransform,
  inject,
  HostBinding,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'logo-icon',
  // templateUrl: '/frontend-assets/icon.svg',
  template: ` <img class="logo" [src]="logoSVG">`,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  styles: [
    `
      .logo {
        display: inline-block; /* Similar to inline elements like <span> */
        width: 3em; /* Width based on font size */
        height: 3em; /* Height based on font size */
        svg {
          width: 100%;
          height: 100%;
          fill: currentColor; /* Use currentColor to apply text color to SVG */
        }
      }
    `,
  ],
})
export class LogoIcon {
  logoSVG = '/frontend-assets/icon.webp';
  @HostBinding('class') currentClass =
    'text-primary flex items-center content-center';
}

// // Your SVG content as a string
// const logoSVG = `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
//  width="300.000000pt" height="300.000000pt" viewBox="0 0 300.000000 300.000000"
//  preserveAspectRatio="xMidYMid meet">
// <metadata>
// Created by potrace 1.10, written by Peter Selinger 2001-2011
// </metadata>
// <g transform="translate(0.000000,300.000000) scale(0.050000,-0.050000)"
//  stroke="none">
// <path d="M4440 4660 c-59 -59 -58 -60 164 -300 203 -219 256 -252 312 -196 54
// 54 22 112 -178 323 -213 225 -234 237 -298 173z"/>
// <path d="M4204 4316 c-217 -204 -216 -188 -41 -372 172 -182 172 -182 246
// -105 64 67 100 76 125 33 53 -93 655 -752 686 -752 45 0 100 52 100 94 0 18
// -166 199 -370 403 l-369 372 72 74 72 74 -147 161 c-191 208 -172 208 -374 18z"/>
// <path d="M3813 4083 c-52 -57 -45 -70 157 -291 212 -231 235 -246 303 -202 74
// 48 59 79 -137 296 -237 263 -254 273 -323 197z"/>
// <path d="M1033 3546 c-31 -52 -31 -995 0 -1030 79 -87 628 -42 627 50 -1 73
// -34 83 -259 77 l-220 -6 -6 456 -5 457 -63 6 c-35 3 -68 -1 -74 -10z"/>
// <path d="M2044 3508 c-381 -190 -400 -772 -30 -972 373 -202 766 57 766 505 0
// 387 -394 638 -736 467z m390 -161 c316 -205 180 -723 -185 -702 -205 11 -328
// 145 -329 361 -1 307 279 493 514 341z"/>
// <path d="M3150 3531 c-296 -145 -279 -454 30 -542 330 -94 391 -161 262 -291
// -73 -72 -189 -78 -341 -18 -114 44 -156 37 -173 -32 -24 -96 125 -168 346
// -168 238 0 369 106 382 310 12 189 -51 243 -401 339 -162 45 -196 132 -90 232
// 53 49 193 52 317 8 86 -30 158 1 158 70 0 96 -348 162 -490 92z"/>
// <path d="M4211 3542 c-27 -11 -82 -122 -182 -364 -78 -191 -169 -409 -200
// -484 -65 -155 -55 -200 45 -210 61 -6 101 54 155 231 13 43 23 45 229 45 l215
// 0 35 -111 c41 -131 92 -191 150 -175 91 23 91 55 2 271 -47 113 -134 331 -194
// 485 -60 154 -116 286 -123 293 -25 24 -93 34 -132 19z m125 -412 c34 -99 67
// -196 75 -215 12 -32 -3 -35 -149 -35 -89 0 -162 7 -162 15 0 40 152 441 162
// 429 6 -8 40 -95 74 -194z"/>
// </g>
// </svg>`;
