import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'template-dialog-frontend',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './frontend-dialog.template.html',
  styles: [
    `
      :host {
        display: block;
        overflow: auto;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// thís is useful to check if host includes some special class
// :host-context {
//   font-style: italic;
// }
export class frontendDialogTemplate implements OnInit {
  dialogRef = inject(DialogRef, { optional: true });
  elementRef = inject(ElementRef);
  @Output() cancelEvent = new EventEmitter();
  @Output() confirmEvent = new EventEmitter();
  @Input() cancel = 'Hủy';
  @Input() confirm = 'Lưu';
  @Input() noAction = false;
  ngOnInit(): void {
    // this is important: allow dialog can scrollable <3
    (this.elementRef.nativeElement as HTMLElement).parentElement?.classList.add(
      'overflow-y-auto',
    );
  }
}
