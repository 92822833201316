import { Routes } from '@angular/router';
import { PeopleLayout } from '../layouts/people/people.layout.js';

export const ProfileRouting: Routes = [
  {
    path: 'profile',
    loadComponent: () =>
      import('../layouts/people/people.layout').then((m) => m.PeopleLayout),
  },
];

export const PeopleRouting: Routes = [
  {
    path: '',
    component: PeopleLayout,
    children: [
      {
        path: 'login',
        loadComponent: () =>
          import('./login/login.page').then(
            (m) => m.LoginPage),
      },
      {
        path: 'register',
        loadComponent: () =>
          import('./register/register.page').then(
            (m) => m.RegisterPage),
      },
      {
        path: 'active-account',
        loadComponent: () =>
          import(
            './active-account/active-account.page'
          ).then((m) => m.ActiveAccountPage),
      },
      {
        path: 'forgot-password',
        loadComponent: () =>
          import(
            './forgot-password/forgot-password.page'
          ).then((m) => m.ForgotPasswordPage),
      },
      {
        path: 'new-password',
        loadComponent: () =>
          import(
            './new-password/new-password.page'
          ).then((m) => m.NewPasswordPage),
      },
      {
        path: 'reset-password',
        loadComponent: () =>
          import(
            './reset-password/reset-password.page'
          ).then((m) => m.ResetPasswordPage),
      },
    ],
  },
];
