import { provideHttpClient, withFetch } from '@angular/common/http';
import { AppTitleStrategy } from './app-title.strategy.js';
import { RouteReuseStrategy, TitleStrategy } from '@angular/router';
import { provideDialogConfig } from '@ngneat/dialog';

import {
  provideAngularQuery,
  QueryClient,
} from '@tanstack/angular-query-experimental';
import { CustomRouteReuseStrategy } from './custom-route-reuse.strategy.js';
import { provideZoneChangeDetection } from '@angular/core';

// import { LucideAngularModule, File, Home, Menu, UserCheck } from 'lucide-angular';
// LucideAngularModule.pick({ File, Home, Menu, UserCheck })

export const frontendProvider = [
  provideHttpClient(withFetch()),
  provideDialogConfig({}),
  provideAngularQuery(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false, // default: true
        },
      },
    }),
  ),
  { provide: TitleStrategy, useClass: AppTitleStrategy },
  { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
  // provideZoneChangeDetection({ eventCoalescing: true })
];
