import { BehaviorSubject } from 'rxjs';
import { PromptInputPayload, PromptPayload } from 'configs/global';

export interface TokenUsage {
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
}

export interface AIStreamResponse {
  usage: TokenUsage;
  snapshot: string;
  subscription: any;
}

export function newTokenUsage() {
  return { prompt_tokens: 0, completion_tokens: 0, total_tokens: 0 };
}

export async function fakeAiStreaming(
  input: string,
  settings: { abortController?: AbortController },
  cb: (snapshot: string, delta: string) => void,
) {
  if (input.length === 0) {
    console.warn('input is empty!!', input);
  }
  input = input.length
    ? input
    : `\n
# **Giới thiệu**\n
this is a fake AI ~world~\n
## world\n
this is new data
`;

  let result: AIStreamResponse = {
    usage: {
      prompt_tokens: 0,
      completion_tokens: 0,
      total_tokens: 0,
    },
    snapshot: '',
    subscription: {},
  };

  const list = ['Fake AI:\n'].concat(input.split(''));
  let snapshot = '';

  const tokenPerSecond = 30;
  const charactersPerToken = 4;
  const milisecondPerChar = 1000 / (tokenPerSecond * charactersPerToken); // use to be 100ms

  for (let i = 0; i < list.length; ) {
    await new Promise((resolve) => setTimeout(resolve, milisecondPerChar));
    const delta = list[i];
    // console.log('delta', delta);
    snapshot += delta;
    cb(snapshot, delta);
    result.snapshot = snapshot;
    i++;

    // console.log('settings.abortController', settings.abortController)
    try {
      if (settings.abortController?.signal.aborted) {
        new DOMException('AbortError', 'The user aborted a request.');
        break;
      }
    } catch (error) {
      console.error('forgot abort?');
    }
  }

  return result;
}

export async function aiStreaming(
  input: string,
  settings: {
    subscription_id: string;
    model_id: string;
    character_id?: string | null;
    body?: Record<string, any>;
    api?: string;
    abortController?: AbortController;
    rankedOnlyResults?: any;
    temperature?: number;
    task?: 'chat' | 'completion';
    systemPrompt?: string;
    max_tokens?: number;
  },
  cb: (snapshot: string, delta: string, isFirst: boolean) => void,
) {
  let isFirst = true;
  const body = JSON.stringify({
    subscription_id: settings.subscription_id,
    model_id: settings.model_id,
    character_id: settings.character_id,
    input: input,
    context: settings.rankedOnlyResults,
    temperature: settings.temperature,
    task: settings.task ?? 'chat',
    systemPrompt: settings.systemPrompt ?? '',
    max_tokens: settings.max_tokens,
    ...(settings.body ?? {}),
  });
  console.log('your body', body);

  let result: AIStreamResponse = {
    usage: {
      prompt_tokens: 0,
      completion_tokens: 0,
      total_tokens: 0,
    },
    snapshot: '',
    subscription: {},
  };
  await handleStream(settings, body, (lines_text: string, value: string) => {
    let snapshot = '';
    // console.log('lines_text', lines_text);
    // data.toString() gives this -> data: {"id":"cmpl-7MwK...","object":"text_completion","created":1685702342,"choices":[{"text":" joy","index":0,"logprobs":null,"finish_reason":null}],"model":"text-davinci-003"}
    // lines is an array of below lines
    const lines = lines_text.split('\n').filter((line) => line.trim() !== '');

    for (const line of lines) {
      let delta: string | undefined;
      try {
        const parsed = JSON.parse(line);
        const isMistral = parsed.data?.choices?.length > 0;
        const isOpenAI = parsed.choices?.length > 0;
        const isGoogle = parsed.candidates?.length > 0;
        if (isMistral) {
          delta = parsed.data.choices[0].delta.content;
          // console.log('parsed', parsed);
        } else if (isOpenAI) {
          delta = parsed.choices[0].delta.content;
          // console.log('parsed', parsed);
        } else if (isGoogle) {
          delta = parsed.candidates[0].content.parts[0].text;
        } else {
          // const isAnthorpic = parsed.type;
          switch (parsed.type) {
            case 'message-start':
              break;
            case 'content_block_start':
              break;
            case 'content_block_delta':
              delta = parsed.delta.text;
              break;
            case 'content_block_stop':
              break;
            case 'message_delta':
              break;
            case 'message_stop':
              break;
            default:
              break;
          }
        }
        if (parsed.usage) result.usage = parsed.usage;
        if (parsed.subscription) result.subscription = parsed.subscription as { subscription_balance: number; subscription_amount: number; };
        // console.log('parsed', parsed, result.subscription, result.usage);
      } catch (error) {
        if (error instanceof SyntaxError) {
          // JSON parsing failed, likely due to incomplete JSON string.
          // We'll wait for the next lines to complete the JSON string.
          continue;
        } else {
          // If it's another type of error, log and rethrow it
          console.error('parse failed: ', error);
          console.error('parse line: ', `${line}`);
          delta = line || '';
          throw error;
        }
      }
      
      snapshot += delta || '';
      // console.log('isFirst', isFirst)
      cb(snapshot, delta || '', isFirst);
      if (isFirst === true && (delta || '').trim()?.length > 0) {
        isFirst = false;
      }
      
    }
    result.snapshot = snapshot;
  });
  return result;
}

async function handleStream(
  { api, abortController }: { api?: string; abortController?: AbortController },
  body: string,
  callback: (result_text: string, value: string) => void,
) {
  abortController = abortController ?? new AbortController();
  api = api ?? '/v1/api/chat';
  const response = await fetch(api, {
    method: 'POST',
    body: body,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    },
    signal: abortController.signal,
  });
  // Check if the request was successful
  if (!response.ok) {
    // Throw an error if the server response was not ok
    // Check if the response is JSON or text
    const contentType = response.headers.get('content-type');
    let errorData;

    if (contentType && contentType.includes('application/json')) {
      errorData = await response.json();
      throw new Error(errorData.message, { cause: errorData });
    } else {
      errorData = await response.text();
      throw new Error(errorData, { cause: errorData });
    }

  }
  // Using reader encode
  // const reader = response.body!.getReader();
  // using decode stream
  const decoderTransformStream = new TextDecoderStream();
  const reader = response.body!.pipeThrough(decoderTransformStream).getReader();
  await textDecoderStream(reader, callback);
}

async function textDecoderStream(
  reader: ReadableStreamDefaultReader,
  callback: (result_text: string, value: string) => void,
) {
  let total = '';
  while (true) {
    const { done, value } = await reader.read();
    if (done) break;

    // Using reader encode
    // const decodedValue = decoder.decode(chunk);
    // using decode stream
    switch (value) {
      case 'ERROR:rate_limit_exceeded':
        total = 'Lỗi quá lượt truy cập. Rate Limit Exceeded';
        break;
      case 'ERROR:internal_server_error':
        total = 'Lỗi server. Internal Server Error';
        break;
      case 'ERROR:token_limit_reached':
        total = 'Lỗi token đạt giới hạn tối đa. Token Limit Reached';
        break;
      case 'ERROR:api_error':
        total = 'Lỗi API khi trả lời. API Error';
        break;
      case 'ERROR:unknown':
        total = 'Lỗi không xác định. unknown Error';
        break;
      default:
        total += value;
    }
    callback(total, value);
  }
}

export type AIStreamingStatus =
  | 'idle'
  | 'connecting'
  | 'streaming'
  | 'error'
  | 'result';

export interface AIServerPayload {
  promptPayload: PromptPayload;
  promptInputPayload: PromptInputPayload;
  enableFakeAI?: boolean | 'repeat';
  apiPayloadBody: { subscription_id: string, user_id: string, model_id: string };
}

export const streamToAIServer = async (
  state: {
    abortController?: AbortController;
    streamingSnapshot?: BehaviorSubject<string>;
    streamingDelta?: BehaviorSubject<string>;
    streamingStatus?: BehaviorSubject<AIStreamingStatus>;
  },
  aiServerPayload: AIServerPayload,
) => {
  const {
    abortController,
    streamingSnapshot,
    streamingDelta,
    streamingStatus,
  } = state;
  const { promptPayload, promptInputPayload, enableFakeAI, apiPayloadBody } =
    aiServerPayload;
  const { prevText, selectionText, inputText } = promptInputPayload;

  let systemMessage = '';

  if (promptPayload.systemFormat) {
    systemMessage = fillingRegexToPayload(
      {
        pattern: promptPayload.systemFormat.pattern,
        inputTextRule: promptPayload.systemFormat.inputTextRule ?? '$inputText',
        selectionTextRule:
          promptPayload.systemFormat.selectionTextRule ?? '$selectionText',
        previousTextRule:
          promptPayload.systemFormat.previousTextRule ?? '$previousText',
        subsequentTextRule:
          promptPayload.systemFormat.subsequentTextRule ?? '$subsequentText',
      },
      {
        prevText,
        selectionText,
        inputText,
      },
    );
  } else {
    systemMessage = promptPayload.systemPrompt ?? '';
  }

  let userMessage = '';
  if (promptPayload.userFormat) {
    userMessage = fillingRegexToPayload(
      {
        pattern: promptPayload.userFormat.pattern,
        inputTextRule: promptPayload.userFormat.inputTextRule ?? '$inputText',
        selectionTextRule:
          promptPayload.userFormat.selectionTextRule ?? '$selectionText',
        previousTextRule:
          promptPayload.userFormat.previousTextRule ?? '$previousText',
        subsequentTextRule:
          promptPayload.userFormat.subsequentTextRule ?? '$subsequentText',
      },
      {
        prevText,
        selectionText,
        inputText,
      },
    );
  } else if (promptPayload.userPrompt) {
    userMessage = promptPayload.userPrompt;
  } else {
    if (
      promptPayload.systemFormat &&
      promptPayload.systemFormat.pattern.includes('$selectionText')
    ) {
      userMessage = '';
    } else {
      userMessage = selectionText;
    }
  }

  console.log('systemMessage', promptPayload, systemMessage);
  console.log('userMessage', userMessage);
  console.log(
    'enableFakeAI?',
    enableFakeAI,
    enableFakeAI === 'repeat' ? selectionText : '',
  );

  if (!systemMessage) {
    throw new Error('did you forgot system message?');
  }

  streamingStatus?.next('connecting');
  let streamAI!: AIStreamResponse;
  try {
    if (enableFakeAI) {
      streamAI = await fakeAiStreaming(
        enableFakeAI === 'repeat' ? selectionText : '',
        { abortController: abortController },
        (snapshot, delta) => {
          streamingSnapshot?.next(snapshot);
          streamingDelta?.next(delta);
          streamingStatus?.next('streaming');
        },
      );
    } else {
      streamAI = await aiStreaming(
        userMessage,
        {
          model_id: apiPayloadBody.model_id,
          subscription_id: apiPayloadBody.subscription_id,
          abortController,
          systemPrompt: systemMessage,
          task: 'chat',
          max_tokens: undefined
        },
        (snapshot, delta) => {
          streamingSnapshot?.next(snapshot);
          streamingDelta?.next(delta);
          streamingStatus?.next('streaming');
        },
      );
    }

    console.log('streamAIAction result', streamAI);
    streamingStatus?.next('result');
    streamingSnapshot?.next(streamAI.snapshot);
    return streamAI;
  } catch (error) {
    console.error('catch an error:', error);
    streamingStatus?.next('error');
    throw new Error(error);
  }
};

export function fillingRegexToPayload(
  promptFormat: PromptPayload['userFormat'],
  promptInputPayload: PromptInputPayload,
) {
  let message = promptFormat?.pattern || '';
  const { prevText, selectionText, inputText } = promptInputPayload;

  if (promptFormat) {
    message = promptFormat.pattern;
    // EDITOR REGEX
    if (promptFormat.previousTextRule) {
      message = message.replaceAll(promptFormat.previousTextRule, prevText);
    }
    if (promptFormat.subsequentTextRule) {
      message = message.replaceAll(promptFormat.subsequentTextRule, '');
    }
    if (promptFormat.selectionTextRule) {
      message = message.replaceAll(
        promptFormat.selectionTextRule,
        selectionText,
      );
    }
    if (promptFormat.inputTextRule && inputText) {
      message = message.replaceAll(promptFormat.inputTextRule, inputText);
    }
  }
  return message;
}
