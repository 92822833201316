import packageJson from 'package.json';

export const PACKAGE_VERSION = +packageJson.version.replaceAll('.', '');

class LocalStorageSetting {
  constructor() {}

  createLocalStorageSetting() {
    console.log('creating localstorage setting...');
  }

  getSetting() {
    const versionNumber = localStorage.getItem('active');
    localStorage.getItem(`version-${versionNumber}`);
  }

  setVersionSetting(versionNumber = 1, setting: any) {
    localStorage.setItem(`version-${versionNumber}`, setting);
  }

  setActiveShopId(activeShopId: string) {
    localStorage.setItem('activeShopId', activeShopId);
  }

  //     insert into app_customer.customers_shops(customer_id, user_id, organization_id, shop_id)
  // select customer_id, 'IfrJI6SEb90tK_ulYrWsg', 'Ll-aroNj4UEtXIyxTX0Vi', '7-wz-rCh8Q5AfNeuLOHfS' from app_custome.customers;

  getActiveShopId() {
    // return localStorage.getItem('activeShopId');
    return '7-wz-rCh8Q5AfNeuLOHfS';
  }

  getActiveOrganizationId() {
    return 'Ll-aroNj4UEtXIyxTX0Vi';
  }

  getActiveWarehouseId() {
    return 'WAIXY0rBv-lGVpwrW84n9';
  }

  setOrganizationId(activeOrganizationId: string) {
    localStorage.setItem('activeOrganizationId', activeOrganizationId);
  }

  getOrganizationId() {
    return localStorage.getItem('activeOrganizationId');
  }
}

export const localStorageSetting = new LocalStorageSetting();
