import { Injectable, NgZone, inject } from '@angular/core';
import { environment } from '@app/frontend-core';
import { Observable, Subject, mergeMap, delay, tap, from } from 'rxjs';
import { SocialUserAddon } from './social-user.addon.js';
declare const FB: any;
declare const google: any;
declare const AppleID: any;

@Injectable({ providedIn: 'root' })
export class SocialLoginAddon {
  ngZone = inject(NgZone);
  private facebookOptions = {
    scope: 'public_profile',
    locale: 'vi_VN',
    fields: 'name,email',
    version: 'v17.0',
    appId: environment.production ? '422621499835098' : '1295544404318464',
    autoLogAppEvents: true,
    cookie: true,
    xfbml: true,
  };

  private appleOptions = {
    state: 'random_string_to_prevent_CSRF_attack', // https://sarunw.com/posts/sign-in-with-apple-4/
    nonce: '[NONCE]',
    clientId: 'com.gopolitix.gpx',
    scope: 'name email',
    redirectURI: 'https://www.gopolitix.com/login',
    usePopup: true
  }

  private googleOptions = {
    client_id: '685109363322-ukpg1v343457t18lk02snienqkff60ea.apps.googleusercontent.com'
  }

  /**
   * tutorial here https://dev.to/heyitsarpit/how-to-add-signin-with-apple-on-your-website-43m9
 * This function will initialize the `AppleID.auth` object with parameter we pass in.
 */
  // const initApple = () => {
  //   window.AppleID.auth.init({
  //     clientId: "com.example.signin", // This is the service ID we created.
  //     scope: "name email", // To tell apple we want the user name and emails fields in the response it sends us.
  //     redirectURI: "http://example.com", // As registered along with our service ID
  //     state: "origin:web", // Any string of your choice that you may use for some logic. It's optional and you may omit it.
  //     usePopup: true, // Important if we want to capture the data apple sends on the client side.
  //   });
  // };


  googleLoginEvent$ = new Subject<SocialUserAddon>();
  handleCredentialResponseForGoogle = (user: SocialUserAddon) => {
    console.log('this one should be replace by login function')
   };
  constructor() {
    this.googleLoginEvent$.subscribe($ => {
      console.log('hi, google result', $)
    })
  }

  logout(provider: any, all = false) {
    switch (provider) {
      case 'facebook':
        FB.logout();
        break;
      case 'google':
        console.log('google new version dont have logout');
        break;

      default:
        console.log(provider);
        break;
    }
  }


  loadApple() {
    return new Promise<void>((resolve, reject) => {
      try {
        const initializeAppleAuth = () => {
          AppleID.auth.init({
            ...this.appleOptions,
          });
          resolve();
        };

        if ((window as any).AppleID) {
          initializeAppleAuth();
        } else {
          this.loadScript('apple-login',
            'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
            initializeAppleAuth
          );
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  loadGG() {
    return new Promise<void>((resolve, reject) => {
      try {
        if (window &&(window as any).google) {
          resolve();
        } else {
          this.loadScript('google-client-script', `//accounts.google.com/gsi/client`, () => {
            resolve();
          });
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  loadFB() {
    return new Promise<void>((resolve, reject) => {
      try {
        if ((window as any).FB) {
          FB.init({
            ...this.facebookOptions,
          });
          resolve();
        } else {
          this.loadScript('facebook-client-script', `//connect.facebook.net/${this.facebookOptions.locale}/sdk.js`, () => {
            FB.init({
              ...this.facebookOptions,
            });
            resolve();
          });
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  private parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  private loadScript(id: string, src: string, onload: any, parentElement: HTMLElement | null = null) {
    // get document if platform is only browser
    if (typeof document !== 'undefined' && !document.getElementById(id)) {
      const signInJS = document.createElement('script');
      signInJS.id = id;
      signInJS.async = true;
      signInJS.src = src;
      signInJS.onload = onload;

      if (!parentElement) {
        parentElement = document.head;
      }

      parentElement.appendChild(signInJS);
    }
  }

   async googleRenderButton(action: 'login' | 'register', callback: (user:SocialUserAddon) => void, options: { elementId: string, type: string, shape: string } = { elementId: 'login-google', type: 'standard', shape: 'pill' }) {
    await this.loadGG();
     const id = options.elementId;
     await new Promise(resolve => setTimeout(resolve, 100));

     google.accounts.id.initialize({
       // https://developers.google.com/identity/gsi/web/guides/client-library
       // When you perform local tests or development, you must add both http://localhost and http://localhost:<port_number> to the Authorized JavaScript origins box.
       // => Authorized JavaScript origins need to have: localhost (THIS IS IMPORTANT, MUST HAVE), localhost:4200
       client_id: this.googleOptions.client_id,
       callback: (result: { credential: string }) => {
         // console.log(result);
         const ggUser = this.parseJwt(result.credential);
         const user = new SocialUserAddon();
         user.provider = 'google';
         user.providerId = ggUser.sub;
         user.providerData = ggUser;
         user.identifier = ggUser.sub;
         user.refreshToken = result.credential;
         user.email = ggUser.email;
         user.username = ggUser.email;
         user.avatarUrl = ggUser.picture;
         user.displayName = ggUser.name;
         user.accessToken = result.credential;
         console.log('google user result', user);
         this.ngZone.run(() => {
           callback(user);
         })
       },
     });

     
    google.accounts.id.renderButton(document.getElementById(id), {
      theme: 'outline',
      size: 'large',
      width: 330,
      shape: options.shape, // 'circle', 'pill',
      type: options.type, // icon | standard
      logo_alignment: 'center',
      text: action === 'login' ? 'signin_with' : 'signup_with',
    });
  }


  googleLogin() {
    console.log("using callback while you init gg login");
  }

  appleLogin() {
    return new Promise(async (resolve, reject) => { 
      await this.loadApple();
      AppleID.auth.signIn()
        .then((data: any) => {
          const user = new SocialUserAddon();
          console.log('apple User', data, user);
          resolve(user);
        })
        .catch((err: any) => {
          console.warn('apple login error', err);
          reject(err);
        })
    });
  }

  facebookLogin() {
    return new Promise((resolve, reject) => {
      FB.login((response: { status: string; authResponse: any }) => {
        if (response.status === 'connected') {
          const authResponse = response.authResponse;
          FB.api(
            `/me?fields=name,email,picture,first_name,last_name`,
            (fbUser: { name: string; id: string; email: string; first_name: string; last_name: string }) => {
              console.log('fbUser', fbUser);
              const user = new SocialUserAddon();
              user.provider = 'facebook';
              user.providerId = fbUser.id;
              user.providerData = fbUser;
              user.identifier = fbUser.id;
              user.refreshToken = authResponse.accessToken;
              user.email = fbUser.email;
              user.username = fbUser.email;
              user.avatarUrl = 'https://graph.facebook.com/' + fbUser.id + '/picture?type=normal&access_token=' + authResponse.accessToken;
              user.displayName = fbUser.name;
              user.accessToken = authResponse.accessToken;
              // user.response = fbUser;
              console.log('facebook nexted', user);
              resolve(user);
            },
          );
        } else {
          reject(response);
        }
      }, { scope: 'public_profile,email' });
    })
  }

}
