import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, computed, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { ActionEvent, toggleElementOnClick } from '@app/frontend-core';
import { CharacterGear, MessageGear, PeopleGear, SubscriptionGear } from '@app/frontend-state';
import { informationOfAIModels } from 'generated/ai-models-info.generated';
import { ChatterNavbarState } from '../chatter-navbar.state';
import { AppMessageConversation } from '@app/generated';
import { AICharacterInformation } from 'generated/ai-characters-info.generated';
import { ChevronDown, ChevronRight, EllipsisVertical, LucideAngularModule, Plus } from 'lucide-angular';

@Component({
    standalone: true,
    imports: [CommonModule, LucideAngularModule],
    selector: 'app-chat-left-menu',
    styles: [`
        :host {
            overflow: hidden;
            display: block;
        }
        `],
    templateUrl: 'chat-left-bar-menu.component.html',
})

export class ChatLeftMenuComponent {
    icons = { ChevronDown, ChevronRight, EllipsisVertical }
    collapseTabs = [
        {
            id: 'recent-characters',
            name: 'N.vật gần đây',
            opened: true,
            limit: 7,
            // action: {
            //   actionType: 'clear-character',
            //   actionName: 'chọn',
            //   actionIcon: faIdCard
            // }
        },

        {
            id: 'conversations',
            name: 'Hội thoại',
            opened: true,
            action: {
                actionType: 'new-conversation',
                actionName: 'mới',
                actionIcon: Plus
            }
        },

    ];

    toggleElementOnClick = toggleElementOnClick;
    chatterNavbarState = inject(ChatterNavbarState);
    characterGear = inject(CharacterGear);
    messageGear = inject(MessageGear);
    elementRef = inject(ElementRef);
    peopleGear = inject(PeopleGear)
    conversations = this.messageGear.getConversations();
    subscriptionGear = inject(SubscriptionGear)
    router = inject(Router);
    recentCharacters = computed(() =>
        this.characterGear.informationOfCharacters
            .filter(char => this.conversations.data()?.find(c =>
                c?.conversationUserIds?.includes(char.characterId))));
    // recentCharacters = computed(() => this.conversations.data()?.map(c =>
    //   informationOfCharacters[0]));

    activeTutorialTab = signal(0);

    action(event: ActionEvent) {
        let toggleMenuOnMobile = true;
        switch (event.type) {
            case 'upgrade':
                this.router.navigate(['/pricing']);
                break;
            case 'new-conversation':
                this.messageGear.activeConversationId.set(null);
                const character = this.characterGear.characterUser();
                if (character) {
                    this.router.navigate(['c', character.userId]);
                }
                break;
            case 'select-conversation': {
                const conversation = (event.data as AppMessageConversation);
                this.messageGear.activeConversationId.set(conversation.conversationId);
                // TODO: able to change character in the middle of conversation
                const findCharacterId = conversation.conversationUserIds?.find((userId) => userId !== this.peopleGear.myProfile()?.userId);
                if (!findCharacterId) {
                    throw new Error('character not detected');
                }
                this.characterGear.currentCharacterId.set(findCharacterId);
                this.router.navigate(['c', findCharacterId], {
                    queryParamsHandling: 'merge',
                    queryParams: {
                        c: conversation.conversationId
                    }
                });
                break;
            }
            case 'select-character': {
                const character = (event.data as AICharacterInformation);
                this.characterGear.currentCharacterId.set(character.characterId);
                this.messageGear.activeConversationId.set(null);
                this.router.navigate(['c', character.characterId]);
                break;
            }
            case 'clear-character': {
                this.characterGear.setDefaultCharacter();
                this.messageGear.activeConversationId.set(null);
                this.router.navigate(['c']);
                break;
            }

            case 'delete-conversation': {
                toggleMenuOnMobile = false;
                const conversationId = (event.data as AppMessageConversation).conversationId;
                if (conversationId) {
                    this.messageGear.removeConversation.mutate({ conversationId }, {
                        onSuccess: () => {
                            this.messageGear.activeConversationId.set(null);
                            console.log('removed', conversationId, this.messageGear.activeConversationId());
                        }
                    });
                }
                break;
            };


            default:
                break;
        }
        if (toggleMenuOnMobile) {
            const isMobile = window.screen.width < 768;
            if (isMobile) {
                this.toggleDrawerMenu(false);
            }
        }

    }

    toggleDrawerMenu(open?:boolean) {
        this.chatterNavbarState.chatLeftBarsMenu.set(typeof open === 'boolean' ? open : !this.chatterNavbarState.chatLeftBarsMenu());
    }
}