import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogRef } from '@ngneat/dialog';
import { LogoIcon } from '@app/frontend-ui';
import { frontendDialogTemplate } from '../frontend-dialog.template/frontend-dialog.template.js';

@Component({
  selector: 'dialog-question-answer',
  standalone: true,
  imports: [CommonModule, frontendDialogTemplate, LogoIcon],
  templateUrl: './question-answer.dialog.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionAnswerDialog {
  dialogRef = inject(DialogRef, { optional: true });
  data = this.dialogRef?.data;
}
