import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { ActionEvent, toggleElementOnClick } from '@app/frontend-core';
import { PeopleGear, SubscriptionGear } from '@app/frontend-state';
import { LayoutGrid, LogOut, LucideAngularModule, Package, Store } from "lucide-angular"
import { ChangeThemeComponent } from 'frontend-ui/src/components/change-theme.component';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'organ-navright',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    LucideAngularModule,
    ChangeThemeComponent
  ],
  template: `

  @if(peopleGear.myProfile(); as user) {
    <div class="h-96 p-4 md:p-8">
      <!-- <pre> subscriptionGear.currentSubscription() {{ subscriptionGear.currentSubscription() | json }} </pre> -->
      @if(subscriptionGear.currentSubscription(); as sub) {
      <div class="grid grid-cols-2 h-12 pt-2">
        <div class="col-span-1 h-full flex flex-col items-center">
          <span class="text-xs py-1">username: {{ peopleGear.myProfile()?.username }}</span>
          <app-change-theme></app-change-theme>
        </div>
        <div class="col-span-1 text-center">
          <span class="text-xs">Gói: {{ subscriptionGear.currentSubscriptionName() }} </span>
          <div class="text-center text-lg">
            <span class="inline">
              <span class="scale-75 text-xs w-9 h-9 inline-block text-center radial-progress text-success"
              [style]="'--size:12rem; --thickness: 2px;' + '--value:' + (sub?.subscriptionBalance / sub?.subscriptionAmount) * 100" role="progressbar">
                {{ (sub?.subscriptionBalance / sub?.subscriptionAmount) * 100 | number:'1.0-0' }}%
              </span>
              <span>{{ sub?.subscriptionBalance | currency:'VND':'symbol':'1.0-0' }}</span>
            </span>
          </div>
          
        </div>
      </div>
      }
    
    <ul tabindex="0" class="menu menu-compact mt-3 p-2 shadow bg-base-100 rounded-box w-full">
      <li (click)="action({type: 'open-subscription'})">
        <span class="py-2 flex items-center text-sm text-base-content cursor-pointer">
          <lucide-icon [name]="icons.Package"></lucide-icon>
          Quản lý đăng ký
        </span>
      </li>
      <li (click)="action({type: 'go-upgrade'})">
        <span class="py-2 flex items-center text-sm text-base-content cursor-pointer">
          <lucide-icon [name]="icons.Store"></lucide-icon>
          Nâng cấp
        </span>
      </li>
      <li (click)="action({type: 'logout'})">
        <span class="py-2 flex items-center text-sm text-base-content cursor-pointer">
          <lucide-icon [name]="icons.LogOut"></lucide-icon>
          Đăng xuất
        </span>
      </li>
    </ul>
  </div>
  } @else {
    <p> Vui lòng đăng nhập </p>
  }

  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavrightOrgan {
  icons = { LogOut, Package, Store }
  toggleElementOnClick = toggleElementOnClick;
  peopleGear = inject(PeopleGear);
  subscriptionGear = inject(SubscriptionGear);
  dialogRef = inject(DialogRef);
  router = inject(Router);
  
  action(event: ActionEvent) {
    switch (event.type) {
      case 'open-subscription':
        this.subscriptionGear.openSubscriptionDialog();
        break;
      case 'go-upgrade':
        this.router.navigate(['/pricing'])
        break;

      case 'logout':
        this.peopleGear.logoutMutation.mutate(undefined, {
          onSuccess: () => {
            location.reload();
          },
        });
        break;
    }

    this.dialogRef.close();
  }
}
