import { Routes } from '@angular/router';

export const ChatRouting: Routes = [
  {
    path: 'character-store',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/character-store/character-store.page.js').then((m) => m.CharacterStorePage),
        data: {
          reuseComponent: true,
        },
      },
     
    ]
  },
  {
    path: 'home',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/chat-home/chat-home.page.js').then((m) => m.ChatHomePage),
        data: {
          reuseComponent: true,
        },
      },
    ]
  },
  {
    path: ':characterId',
    loadComponent: () =>
      import('./pages/chat-conversation/chat-conversation.page.js').then((m) => m.ChatConversationPage),
    data: {
      reuseComponent: true,
    },
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];
