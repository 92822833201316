import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output, inject, input } from '@angular/core';
import { DropdownItemBase } from 'configs/global';
import { LucideAngularModule } from 'lucide-angular';

@Component({
  selector: 'app-dropdown',
  template: `

@if (objects()) {
@for (obj of objects() | keyvalue; track $index) {
<div class="obj-dropdown">
  <h3 [title]="obj.value?.[0]?.group?.description ?? obj.key" class="text-sm font-bold py-2 px-2">{{
    obj.value?.[0]?.group?.title }}</h3>
  @for (item of obj.value; track $index) {
  <ng-container [ngTemplateOutlet]="dropdownTemplate"
    [ngTemplateOutletContext]="{ item: item, $index: $index}"></ng-container>
  }
</div>
}
} @else {
@for (item of items(); track $index) {
<div class="list-dropdown">
  @if (item.group) {
  <h3 class="text-sm font-bold py-2 px-2">{{ item.group.description }}</h3>
  }
  <ng-container [ngTemplateOutlet]="dropdownTemplate"
    [ngTemplateOutletContext]="{ item: item, $index: $index}"></ng-container>
</div>
}
}

<ng-template #dropdownTemplate let-item="item" let-$index="$index">
  <!-- <pre> $index {{ $index }} selectedIndex() {{ selectedIndex() }}</pre> -->
  <!-- <pre> item.id {{ item.id }} selectedId() {{ selectedId() }}</pre> -->
  <button [title]="item.description"
    class="flex w-full items-center space-x-2 rounded-md px-2 py-1 text-left text-sm hover:bg-accent hover:text-accent-content aria-selected:bg-accent aria-selected:text-accent-content"
    [attr.aria-selected]="($index !== null && $index === selectedIndex()) || (selectedId() && item.id === selectedId() )"
    (click)="selectDropdownItem.emit(item)">
    <div class="flex h-10 w-10 items-center justify-center rounded-md border border-muted border-10 bg-background">
      @if (item.icon) {
      <lucide-icon [name]="item.icon"></lucide-icon>
      } @else if (item.image) {
      <img [src]="item.image" alt="">
      }
    </div>
    <div>
      <p class="font-medium">{{ item.title }}</p>
      <p class="text-xs text-muted-foreground">
        {{ item.description }}
      </p>
    </div>
  </button>
  <hr class="my-2 border-t border-muted-foreground" />
</ng-template>
  `,
  styles: [
    `:host { display: block; }`,
  ],
  standalone: true,
  imports: [CommonModule, LucideAngularModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent {
  items = input<DropdownItemBase[]>([]);
  objects = input<Record<string, DropdownItemBase[] | undefined> | undefined>(undefined);
  selectedIndex = input<number | null | undefined>();
  selectedId = input<string | null | undefined>();
  @Output() selectDropdownItem = new EventEmitter();
  elementRef = inject(ElementRef);
  constructor() { }

}
