import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SocialUserAddon {
  providerId!: string;
  provider!: string;
  providerData!: any;
  accessToken!: string;
  avatarUrl!: string;
  displayName!: string;
  email!: string;
  identifier!: string;
  username!: string;
  refreshToken!: string;

}
