import { Injectable, computed, inject, signal } from '@angular/core';
import {
  DATA_NAVIGATION_MENU,
  EDITOR_MENU_LEFT,
  DATA_MODIFER_MENU,
  DataMenuInterface,
} from 'configs/src/navigation/routing-config';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ChatterNavbarState {
  chatLeftBarsMenu = signal<boolean>(false);


  dataNavigationMenus = signal<DataMenuInterface[]>(DATA_NAVIGATION_MENU);
  editorMenuLeft = signal<DataMenuInterface[]>(EDITOR_MENU_LEFT);
  dataModiferMenus = signal<DataMenuInterface[]>(DATA_MODIFER_MENU);
  chatMenu = computed(() =>
    this.editorMenuLeft().find((dataMenu) => dataMenu.routerId === 'chat'),
  );
  doubleEditorMenu = computed(() =>
    this.editorMenuLeft().find(
      (dataMenu) => dataMenu.routerId === 'double-editor',
    ),
  );
  router = inject(Router);
  constructor() { }
  
  toggleLeftBarsMenu(newValue?: boolean) {
    this.chatLeftBarsMenu.update((value) => {
      // console.log('toggleLeftBarsMenu', newValue, value);
      return newValue ?? !value;
    });
  }

  toggleNavigationMenu(routerId: string) {
    this.dataNavigationMenus.update((dataMenus) => {
      return dataMenus.map((dataMenu) => {
        if (dataMenu.routerId === routerId) {
          dataMenu.isActive = true;
          dataMenu.linkTo && this.router.navigateByUrl(dataMenu.linkTo);
        } else {
          dataMenu.isActive = false;
        }
        return dataMenu;
      });
    });
  }

  toggleDataMenu(
    routerId: string,
    mode: 'open' | 'close' | 'toggle' = 'toggle',
  ) {
    this.editorMenuLeft.update((dataMenus) => {
      return dataMenus.map((dataMenu) => {
        if (dataMenu.routerId === routerId) {
          let value = !dataMenu.isActive;
          if (mode === 'open') value = true;
          else if (mode === 'close') value = false;
          dataMenu.isActive = value;
        } else {
          dataMenu.isActive = false;
        }
        return dataMenu;
      });
    });
  }

  toggleModiferDataMenu(
    routerId: string,
    mode: 'open' | 'close' | 'toggle' = 'toggle',
  ) {
    this.dataModiferMenus.update((dataMenus) => {
      return dataMenus.map((dataMenu) => {
        if (dataMenu.routerId === routerId) {
          let value = !dataMenu.isActive;
          if (mode === 'open') value = true;
          else if (mode === 'close') value = false;
          dataMenu.isActive = value;
        } else {
          dataMenu.isActive = false;
        }
        return dataMenu;
      });
    });
  }
}
