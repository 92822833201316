import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, inject, input } from '@angular/core';
import { Router } from '@angular/router';
import { MessageGear, CharacterGear } from '@app/frontend-state';
import { ActivatedRoute } from '@angular/router';
import { LucideAngularModule, ChevronLeft } from 'lucide-angular';
@Component({
  standalone: true,
  imports: [CommonModule, LucideAngularModule],
  selector: 'app-character-avatar',
  template: `
@if(characterGear.characterUser(); as characterUser) {
  <div class="avatar placeholder items-center">
    @if(backBtn()) {
      <button [ngClass]="bigAvatar() ? 'btn-md' : 'btn-sm'" class=" btn btn-sm btn-ghost h-full" [hidden]="!backBtn()"
          (click)="back();">
          <lucide-icon [name]="icons.ChevronLeft"></lucide-icon>
        </button>
    }
    
    <div [ngClass]="bigAvatar() ? 'w-12 md:w-16 h-12 md:h-16' : 'w-6 md:w-12 h-6 md:h-12'" class="ml-0 md:ml-1 rounded-full">
      <!-- <span class="bg-neutral text-neutral-content text-lg md:text-2xl">{{ characterUser.displayName | slice:0:1 }}</span> -->
       <img [src]="'/v1/api/avatar?username=' + characterUser.username" alt="avatar"/>
    </div>

    <span [ngClass]="bigAvatar() ? ' text-base md:text-lg' : ' text-sm md:text-base'"  class="self-center pl-2 flex flex-col">
      <span class="font-bold truncate max-w-32 md:max-w-full"> {{ characterUser.displayName }} </span>
      <!-- <span> {{ characterUser.username }} </span> -->
    </span>
  </div>
}
    `,
})

export class CharacterAvatarComponent {
  bigAvatar = input(false);
  backBtn = input(true);
  messageGear = inject(MessageGear);
  characterGear = inject(CharacterGear);
  router = inject(Router);
  route = inject(ActivatedRoute);
  location = inject(Location);
  icons = { ChevronLeft };

  back() {
    const state = this.location.getState() as { navigationId: number, back: string };
    // this.location.back();
    console.log('back', this.router.url, this.location.getState()); // should log out 'bar');
    if (state.back) {
      this.router.navigate([state.back], { replaceUrl: true });
    } else {
      const previousUrl = this.router.url.split('/').slice(0, -1).join('/');
      this.router.navigate([previousUrl], { replaceUrl: true });
    }
  }
}