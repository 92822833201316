import { ChangeDetectorRef, Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  BaseRouteReuseStrategy,
  RouteReuseStrategy,
  Route,
  NavigationStart,
  NavigationEnd,
} from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  // LINK: https://itnext.io/cache-components-with-angular-routereusestrategy-3e4c8b174d5f
  private handlers: Map<Route, DetachedRouteHandle> = new Map();

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data['reuseComponent'];
  }

  public store(
    route: ActivatedRouteSnapshot,
    handle: DetachedRouteHandle,
  ): void {
    if (!route.routeConfig) return;
    this.handlers.set(route.routeConfig, handle);
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig && !!this.handlers.get(route.routeConfig);
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig || !this.handlers.has(route.routeConfig))
      return null;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.handlers.get(route.routeConfig)!;
  }

  public shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot,
  ): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}


import { Location, ViewportScroller } from '@angular/common';
import { OnDestroy } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { filter, takeUntil, Subject, delay, tap, BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RouteScrollService implements OnDestroy {
  destroy$ = new Subject();
  routeEvent$ = new BehaviorSubject<any>(null);
  router = inject(Router);
  viewportScroller = inject(ViewportScroller);
  location = inject(Location);
  // https://github.com/angular/angular/issues/55383
  // https://v17.angular.io/api/router/InMemoryScrollingOptions
  start() {
    // https://github.com/ionic-team/ionic-framework/blob/a745c6f4bc4334d211a1acb793df83f0bf3fc2ef/packages/angular/common/src/providers/nav-controller.ts#L22
    // https://github.com/angular/angular/blob/001f3198658edfdee5c6d5a6c843a801faf3387f/packages/router/src/statemanager/state_manager.ts#L127

    console.log('RouteScrollService')
    // https://angular.io/api/router/ExtraOptions#scrollPositionRestoration
    this.router.events
      .pipe(
        tap((event) => this.routeEvent$.next(event)),
        tap((event) => {
          // https://github.com/angular/angular/blob/001f3198658edfdee5c6d5a6c843a801faf3387f/packages/router/src/router_scroller.ts#L68
          if (event instanceof NavigationStart) {
            console.log('NavigationStart event: ', event, this.viewportScroller.getScrollPosition(), [window.scrollX, window.scrollY], this.router);
          } else if (event instanceof NavigationEnd) {
            // console.log('NavigationStart event: ', event);
          }
        }),
        filter((e): e is Scroll => e instanceof Scroll),
        // tap((event) => console.log('Scroll event:', event, this.router)),
        // delay(1),
        takeUntil(this.destroy$)
      )
      .pipe()
      .subscribe({
        next: (e) => {
          if (e.position) {
            console.log('e.position', e.position, [window.scrollX, window.scrollY]);
            // NOTE: No need to scroll since we use bfcache
            // NOTE: this is fallback if we don't use bfcache because desktop dont have this
            // NOTE:  we restore scroll position manually
            setTimeout(() => {
              e.position && this.viewportScroller.scrollToPosition(e.position);
            }, 1);
          } else if (e.anchor) {
            console.log('e.anchor', e.anchor);
            this.viewportScroller.scrollToAnchor(e.anchor);
          } else {
            console.log('scrollToPosition([0, 0]', this.viewportScroller);
            // forward navigation scroll top on new navigation
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        },
        error: (err) => {
          console.warn('watching error', err);
        },
        complete: () => {
          console.log('watching completed');
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}


