import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SubscriptionGear } from '@app/frontend-state';
import { DialogRef } from '@ngneat/dialog';
import { PackageInformationComponent } from 'frontend-ui/src/components/package-information.component';

@Component({
  selector: 'app-manage-subscription',
  templateUrl: 'manage-subscription.dialog.html',
  standalone: true,
  imports: [CommonModule, RouterLink, PackageInformationComponent],
})
export class ManageSubscriptionDialog {
  dialogRef = inject(DialogRef);
  subscriptionGear = inject(SubscriptionGear);

}
