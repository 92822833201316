import { CommonModule } from "@angular/common";
import { Component, inject, input, TemplateRef } from "@angular/core";
import { ChatterNavbarState } from "../chatter-navbar.state";
import { ChatLeftMenuComponent } from "../navbar/chat-left-bar-menu.component";
import { RouterOutlet } from "@angular/router";

@Component({
    selector: 'layout-chat',
    standalone: true,
    imports: [
        CommonModule,
        ChatLeftMenuComponent,
        RouterOutlet
    ],
    template: `
<div class="drawer h-full" [ngClass]="chatterNavbarState.chatLeftBarsMenu() ? 'md:drawer-open' : ''">
  <input title="drawer" id="left-chat-drawer" type="checkbox" class="drawer-toggle"
    [attr.checked]="chatterNavbarState.chatLeftBarsMenu() ? '' : null" />

  <div class="drawer-content px-2 flex flex-col">
    <ng-container [ngTemplateOutlet]="topNavbarTemplate() ?? defaultTemplate"></ng-container>

    <div class="flex-1 relative mx-auto w-full md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg">
      <router-outlet></router-outlet>
    </div>

    @if(currentURL().startsWith('/c') && !currentURL().includes('?c=')) {
      <ng-container [ngTemplateOutlet]="bottomNavigationTemplate() ?? defaultTemplate"></ng-container>
    }

  </div>
  <div class="drawer-side">
    <label (click)="chatterNavbarState.chatLeftBarsMenu.set(false)" aria-label="close sidebar" class="drawer-overlay"></label>
    <app-chat-left-menu class="pt-6 md:pt-0 bg-base-300 h-full overflow-y-auto w-[85vw] md:w-[260px]">
    </app-chat-left-menu>
  </div>
</div>

<ng-template #defaultTemplate>
  <!-- Default empty content or any default content -->
</ng-template>

    `,
    styles: [ `:host { display: flex; flex: 1 1 0%;}`],
})
export class ChatLayout {
    currentURL = input<string>('/');
    topNavbarTemplate = input<TemplateRef<any>>();
    bottomNavigationTemplate = input<TemplateRef<any>>();
    chatterNavbarState = inject(ChatterNavbarState);
}