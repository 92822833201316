<section class="relative h-full">
  <div class="flex justify-center flex-col h-full p-4">
    <p
      [innerHTML]="data.question"
      class="py-4 px-8 text-gray-text text-base md:text-lg text-center font-bold  rounded-t-md nunito"
    ></p>

    <div
      class="flex justify-center md:justify-end py-2  border-t px-8 rounded-b-md"
    >
      <button
        (click)="yes()"
        class="hover: hover:bg-primary text-primary border py-1.5 px-5 mr-4 border-primary transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-100 no-underline text-xs sm:text-sm font-medium bg-green rounded cursor-pointer flex outline-none"
      >
        <div>{{ data.yes }}</div>
      </button>

      <button
        (click)="no()"
        class="hover: hover:bg-primary text-primary border py-1.5 px-2 border-primary transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-100 no-underline text-xs sm:text-sm font-medium bg-green rounded cursor-pointer flex outline-none"
      >
        <div>{{ data.no }}</div>
      </button>
    </div>
  </div>
</section>
