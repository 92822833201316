import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  inject,
} from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
@Component({
  standalone: true,
  selector: 'app-confirm',
  imports: [CommonModule],
  templateUrl: './confirm.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class ConfirmComponent implements OnInit {
  dialogRef = inject(DialogRef);
  cd = inject(ChangeDetectorRef);
  data = this.dialogRef.data;

  constructor() {}

  ngOnInit() {
    console.log('confirm component data', this.data);
    // mat dialog not open full and need workaround to trigger
    setTimeout(() => {
      this.cd.detectChanges();
    }, 100);
  }

  yes() {
    this.dialogRef.close(true);
  }

  no() {
    this.dialogRef.close();
  }
}
