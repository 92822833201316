import { Component, inject, Input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LucideAngularModule, Folder, File } from 'lucide-angular';
import { FileManagerService, TreeNode } from '../chatter-dashboard/pages/editor/tiptap/file-manager.service';


@Component({
    selector: 'app-file-manager',
    standalone: true,
    imports: [CommonModule, LucideAngularModule],
    styles: [`:host { display: block; }`],
    template: `
@if (dataTree().length > 0) {
<ng-container [ngTemplateOutlet]="treeTemplate" [ngTemplateOutletContext]="{ tree: dataTree(), root: true }"]></ng-container>
} @else {
    <div>
        <button class="btn" (click)="openFolder()" title="mở file">
            <lucide-icon [name]="icons.Folder"></lucide-icon>
            Open Folder
        </button>
    </div>
}


<ng-template #treeTemplate let-tree="tree" let-root="root">
    <ul class="menu menu-xs bg-base-200 rounded-lg w-full max-w-xs">
        <ng-container *ngFor="let node of tree">
        <li *ngIf="node.type === 'file'">
            <a class="truncate" (click)="openFile(node)">
            <lucide-icon [name]="icons.File"></lucide-icon>
            {{ node.name }}
            </a>
        </li>
        <li *ngIf="node.type === 'folder'">
            <details [attr.open]="root ? '' : null">
            <summary class="truncate">
                <lucide-icon [name]="icons.Folder"></lucide-icon>
                {{ node.name }}
            </summary>
            @if(node.children; as children) {
                <ng-container [ngTemplateOutlet]="treeTemplate" [ngTemplateOutletContext]="{ tree: children }"]></ng-container>
            }
            </details>
        </li>
        </ng-container>
    </ul>
 </ng-template>
  `,
})
export class FileManagerComponent {
    icons = { Folder, File };
    dataTree = signal([] as TreeNode[]);
    fileManagerService = inject(FileManagerService);

    async openFile(node: TreeNode) {
        const result = await this.fileManagerService.openFile(node);
        console.log('open file', node, result);
    }

    async openFolder() {
        const result = await this.fileManagerService.openFolder();
        if (result) {
            this.dataTree.set(result);
        }
        console.log('tree', result);
    }
}