<template-dialog-frontend [confirm]="''" [cancel]="'đóng'" class="w-full md:w-auto">
  <span
    dialog-title
    class="text-primary font-bold text-xl md:text-2xl md:pt-0 text-center"
  >
    {{data.title}}
  </span>
  <div class="overflow-auto flex flex-col justify-center items-center">
    <div class="bg-base-100 h-full px-4 pb-12 rounded-xl">
      <p
        class="text-gray-text nunito md:text-lg whitespace-pre-line text-justify"
        [innerHTML]="data.content"
      ></p>
    </div>
  </div>
</template-dialog-frontend>
