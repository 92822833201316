import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
@Injectable({
  providedIn: 'root',
})
export class AuthValidator {
  constructor() {}

  // passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
  //   const passwordCtrl = control.get('password');
  //   const confirmPasswordCtrl = control.get('confirmPassword');
  //   const passwordValue = passwordCtrl?.value;
  //   const confirmPasswordValue = confirmPasswordCtrl?.value;
  //   return passwordValue === confirmPasswordValue ? null : { missMatch: true }
  // }

  // passwordMatchValidator(controlName: string, matchingControlName: string) {
  //   return (formGroup: FormGroup) => {
  //     const control = formGroup.controls[controlName];
  //     const matchingControl = formGroup.controls[matchingControlName];
  //     if (
  //       matchingControl.errors &&
  //       !matchingControl.errors['confirmedValidator']
  //     ) {
  //       return;
  //     }
  //     if (control.value !== matchingControl.value) {
  //       matchingControl.setErrors({ confirmedValidator: true });
  //     } else {
  //       matchingControl.setErrors(null);
  //     }
  //   };
  // }

  addValidatorMatchPassword(
    yourForm: FormGroup,
    controlName1 = 'password',
    controlName2 = 'confirmPassword',
  ) {
    yourForm.addValidators(
      this.createCompareValidator(
        yourForm.controls[controlName1],
        yourForm.controls[controlName2],
      ),
    );
  }

  createCompareValidator(
    controlOne: AbstractControl,
    controlTwo: AbstractControl,
  ) {
    return () => {
      if (controlOne.value !== controlTwo.value)
        return { match_error: 'Value does not match' };
      return null;
    };
  }
}
