import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { DataMenuInterface } from 'configs/src/navigation/routing-config';

import { LucideAngularModule, ExternalLink } from 'lucide-angular';

@Component({
  selector: 'organ-sidebar',
  standalone: true,
  imports: [CommonModule, LucideAngularModule, RouterLink, RouterLinkActive],
  styles: [
    `:host { display: flex; }`,
  ],
  template: `
<div class="py-4 btm-nav self-start sticky top-0 h-auto flex-col justify-center bg-transparent">
  @for (dataMenuItem of dataMenus(); track $index) {
  <button style="flex-basis: content" class="py-6" [title]="dataMenuItem.title">
    <lucide-icon class="h-12 w-12" [name]="dataMenuItem.icon"></lucide-icon>
  </button>
  }
</div>`,

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarOrgan implements OnInit {
  dataMenus = input<DataMenuInterface[]>([]);

  sidebarPosition = input('left');
  sidebarConfig = computed(() => {
    switch (this.sidebarPosition()) {
      case 'bottom':
        return {
          outside: 'w-screen',
          inside: 'w-full rounded-top-x-5xl',
          menuDirection: 'horizontal',
          menuItem: 'px-6 h-10 w-max hover:bg-accent',
          menuAccentItem: '-bottom-0.5 w-4/5 h-1.5 rounded-r-md rounded-l-md',
        };
      case 'left':
        return {
          outside: 'h-[80%] fixed mt-20',
          inside: 'flex-col h-[80%] left-0 rounded-r-5xl ',
          menuDirection: 'vertical',
          menuItem: 'py-6 w-6 md:w-14 hover:bg-accent',
          menuAccentItem: '-left-0.5 h-4/5 w-1.5 rounded-r-md ',
        };
      case 'right':
        return {
          outside: ' h-[80%] fixed mt-20 right-0',
          inside: 'flex-col  h-[80%] right-0 rounded-l-5xl',
          menuDirection: 'vertical',
          menuItem: 'py-6 w-6 md:w-14 hover:bg-accent',
          menuAccentItem: '-right-0.5 h-4/5 w-1.5 rounded-r-md ',
        };
      default:
        return;
    }
  });


  @Output() action = new EventEmitter();
  cd = inject(ChangeDetectorRef);
  router = inject(Router);
  location = inject(Location);
  activatedRoute = inject(ActivatedRoute);
  
  // routeDeepLevel = 1;
  constructor() {
    
  }

  ngOnInit(): void {
    
  }
}
