import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

// https://stackoverflow.com/a/50130338
export function formatISO(date: Dayjs) {
  return date.format('YYYY-MM-DD');
}

export function shiftMonths(date: Dayjs, months: number) {
  return date.add(months, 'months');
}

export function shiftDays(date: Dayjs, days: number) {
  return date.add(days, 'days');
}

export function formatMonthYYYY(date: Dayjs) {
  return date.format('MMMM YYYY');
}

export function formatYear(date: Dayjs) {
  return `Year of ${date.year()}`;
}

export function formatYearShort(date: Date) {
  return date.getUTCFullYear().toString().substring(2);
}

export function formatDay(date: Dayjs) {
  if (date.year() !== dayjs().year()) {
    return date.format('ddd, DD MMM YYYY');
  } else {
    return date.format('ddd, DD MMM');
  }
}

export function formatDayShort(date: Dayjs, includeYear = false) {
  if (includeYear) {
    return date.format('D MMM YY');
  } else {
    return date.format('D MMM');
  }
}

export function formatDateRange(
  site: { offset: number },
  from: Dayjs,
  to: Dayjs,
) {
  if (!from || !to) return;
  if (typeof from === 'string') from = parseUTCDate(from);
  if (typeof to === 'string') to = parseUTCDate(to);

  if (from.isSame(to)) {
    return formatDay(from);
  } else if (from.isSame(to, 'year')) {
    const includeYear = !isThisYear(site, from);
    return `${formatDayShort(from, false)} - ${formatDayShort(to, includeYear)}`;
  } else {
    return `${formatDayShort(from, true)} - ${formatDayShort(to, true)}`;
  }
}

export function parseUTCDate(dateString: string) {
  return dayjs.utc(dateString);
}

export function parseNaiveDate(dateString: string) {
  return dayjs(dateString);
}

export function nowForSite(site: { offset: number }) {
  return dayjs.utc().utcOffset(site.offset / 60);
}

export function lastMonth(site: { offset: number }) {
  return shiftMonths(nowForSite(site), -1);
}

export function isSameMonth(date1: Dayjs, date2: Dayjs) {
  return formatMonthYYYY(date1) === formatMonthYYYY(date2);
}

export function isToday(site: { offset: number }, date: Dayjs) {
  return formatISO(date) === formatISO(nowForSite(site));
}

export function isThisMonth(site: { offset: number }, date: Dayjs) {
  return formatMonthYYYY(date) === formatMonthYYYY(nowForSite(site));
}

export function isThisYear(site: { offset: number }, date: Dayjs) {
  return date.year() === nowForSite(site).year();
}

export function isBefore(date1: Dayjs, date2: Dayjs, period: 'year' | 'month') {
  /* assumes 'day' and 'month' are the only valid periods */
  if (date1.year() !== date2.year()) {
    return date1.year() < date2.year();
  }
  if (period === 'year') {
    return false;
  }
  if (date1.month() !== date2.month()) {
    return date1.month() < date2.month();
  }
  if (period === 'month') {
    return false;
  }
  return date1.date() < date2.date();
}

export function isAfter(date1: Dayjs, date2: Dayjs, period: 'year' | 'month') {
  /* assumes 'day' and 'month' are the only valid periods */
  if (date1.year() !== date2.year()) {
    return date1.year() > date2.year();
  }
  if (period === 'year') {
    return false;
  }
  if (date1.month() !== date2.month()) {
    return date1.month() > date2.month();
  }
  if (period === 'month') {
    return false;
  }
  return date1.date() > date2.date();
}
