
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    status: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor() { }

    showToast(
        message: string,
        typeToast: 'success' | 'warning' | 'error' | '' = '',
        toastConfig = {},
    ) {
        this.status.next({ message: message, typeToast: typeToast, toastConfig });
    }
}