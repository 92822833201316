export interface AIModelInformation {
    model_id: string;
    // model_provider: string;
    // model_id_of_provider: string;
    model_username: string;
    model_display_name: string;
    model_family: string;
    model_status: string;
    model_type: string;
    model_description: string;
    model_image: string | null;
    model_rank: number;
    model_price: number;
    // model_cost: number;
    model_stop?: string[];
}


export const informationOfAIModels: AIModelInformation[] = [
    {
        "model_id": "losa_elementary-model-v1",
        "model_username": "losa-elementary-model-v1",
        "model_display_name": "Miu bé nhỏ",
        "model_family": "gemini",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 1,
        "model_description": "Mô hình ngôn ngữ sơ cấp",
        "model_image": "/frontend-assets/images/model-family/losa.webp",
        "model_price": 0.03
    },
    {
        "model_id": "google_gemini-1.5-flash",
        "model_username": "gemini-15-flash",
        "model_display_name": "Gemini 1.5 Flash",
        "model_family": "gemini",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 2,
        "model_description": "Mô hình ngôn ngữ trung cấp",
        "model_image": "/frontend-assets/images/model-family/gemini.jpeg",
        "model_price": 0.045
    },
    {
        "model_id": "openAI_gpt-4o-mini",
        "model_username": "gpt-4o-mini",
        "model_display_name": "gpt 4o mini",
        "model_family": "gpt",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 2,
        "model_description": "Mô hình ngôn ngữ trung cấp",
        "model_image": "/frontend-assets/images/model-family/openAI.jpeg",
        "model_price": 0.1
    },
    {
        "model_id": "togetherAI_Qwen2-72B-Instruct",
        "model_username": "Qwen2-72B-Instruct",
        "model_display_name": "Qwen2 72B",
        "model_family": "gpt",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 2,
        "model_description": "Mô hình ngôn ngữ trung cấp",
        "model_image": "/frontend-assets/images/model-family/qwen.jpeg",
        "model_price": 0.12
    },
    {
        "model_id": "togetherAI_llama-v3p1-70b-instruct",
        "model_username": "llama-31-70b-instruct",
        "model_display_name": "Llama 3.1 70B",
        "model_family": "gpt",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 2,
        "model_description": "Mô hình ngôn ngữ trung cấp",
        "model_image": "/frontend-assets/images/model-family/llama.jpeg",
        "model_price": 0.12
    },
    {
        "model_id": "claude-3-haiku",
        "model_username": "claude-3-haiku",
        "model_display_name": "Haiku",
        "model_family": "claude",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 2,
        "model_description": "Mô hình ngôn ngữ trung cấp",
        "model_image": "/frontend-assets/images/model-family/claude.jpeg",
        "model_price": 0.18
    },
    {
        "model_id": "togetherAI_llama-v3p1-405b-instruct",
        "model_username": "llama-31-405b-instruct",
        "model_display_name": "Llama 3.1 405b",
        "model_family": "gpt",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 3,
        "model_description": "Mô hình ngôn ngữ thượng cấp",
        "model_image": "/frontend-assets/images/model-family/llama.jpeg",
        "model_price": 0.45
    },
    {
        "model_id": "openAI_gpt-4o",
        "model_username": "gpt-4o",
        "model_display_name": "gpt 4o",
        "model_family": "gpt",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 3,
        "model_description": "Mô hình ngôn ngữ thượng cấp",
        "model_image": "/frontend-assets/images/model-family/openAI-level-3.jpeg",
        "model_price": 0.7
    },
    {
        "model_id": "mistral_mistral-large-latest",
        "model_username": "mistral-large-latest",
        "model_display_name": "Mistral Large",
        "model_family": "gpt",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 3,
        "model_description": "Mô hình ngôn ngữ thượng cấp",
        "model_image": "/frontend-assets/images/model-family/mistral.jpeg",
        "model_price": 1.3
    },
    {
        "model_id": "claude-3-sonnet",
        "model_username": "claude-3-sonnet",
        "model_display_name": "Sonet 3.5",
        "model_family": "claude",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 3,
        "model_description": "Mô hình ngôn ngữ thượng cấp",
        "model_image": "/frontend-assets/images/model-family/claude-level-3.jpeg",
        "model_price": 1.5
    },
    {
        "model_id": "google_gemini-1.5-pro",
        "model_username": "gemini-15-pro",
        "model_display_name": "Gemini 1.5 Pro",
        "model_family": "gemini",
        "model_status": "active",
        "model_type": "text-generation",
        "model_rank": 3,
        "model_description": "Mô hình ngôn ngữ thượng cấp",
        "model_image": "/frontend-assets/images/model-family/gemini.jpeg",
        "model_price": 2.1
    }
];