<ng-template #topNavbarTemplate>
  <div
    class="navbar sticky top-0 
    bg-base-100 text-base-content z-30 bg-opacity-90 backdrop-blur transition-shadow duration-100 [transform:translate3d(0,0,0)]  w-full  min-h-6 h-6 md:min-h-10 md:h-10"
    style="--navbar-padding: 0px">
    <div class="navbar-start">
      <label (click)="chatterNavbarState.toggleLeftBarsMenu();" aria-label="open sidebar"
        class="btn btn-primary drawer-button btn-ghost">
        <lucide-icon [name]="icons.Menu"></lucide-icon>
      </label>

      @if (currentURL()?.includes('?c=') && characterGear.characterUser()) {
      <app-character-avatar class="h-full"></app-character-avatar>
      }
    </div>

    @if(!currentURL()?.includes('?c=')) {
      <div class="navbar-center">
        <a routerLink="/" class="btn btn-xs md:btn-sm btn-ghost h-full flex justify-center items-center">
          <img class="w-6 h-6 md:w-9 md:h-9 " src="/frontend-assets/icon.webp" />
        </a>
        <!-- for mobile -->
        <ng-container [ngTemplateOutlet]="tabListMobileTemplate"></ng-container>
        <!-- for desktop -->
        <div class="flex-none hidden md:flex w-fit">
          <ng-container [ngTemplateOutlet]="tabListDesktopTemplate"></ng-container>
        </div>
      </div>
    }




    <div class="navbar-end">
      <div (click)="action({type: 'open-menu-navright'})" aria-label="open menu" class="btn btn-ghost btn-sm w-max">
        <lucide-icon [name]="icons.User"></lucide-icon>
      </div>
    </div>
  </div>
</ng-template>





@if (peopleGear.myProfileQuery.data()) {
  @if (currentURL()?.startsWith('/c')) {
    <layout-chat [currentURL]="currentURL() || '/'" [topNavbarTemplate]="topNavbarTemplate"
      [bottomNavigationTemplate]="bottomNavigationTemplate"></layout-chat>
  }@else if (currentURL()?.startsWith('/d')) {
    <layout-editor [currentURL]="currentURL() || '/'" [topNavbarTemplate]="topNavbarTemplate"
      [bottomNavigationTemplate]="bottomNavigationTemplate"></layout-editor>
  } @else {
    <router-outlet></router-outlet>
  }
} @else {

  <!-- <pre> !!peopleGear.myProfileQuery.data() {{ !!peopleGear.myProfileQuery.data() }} {{ peopleGear.myProfileQuery.data() | json}} </pre> -->

<div class="h-dvh flex justify-center items-center flex-col max-auto">
  <p class="text-base-content/70 font-title py-4 font-semibold md:text-lg xl:text-2xl text-center">
    LOSA AI - thư ký AI trực tuyến đáng yêu
    <br />
    công cụ lao động mới
    <span class="border-base-content/20 border-b-2"> dùng AI</span> giúp bạn làm
    việc nhanh hơn.
  </p>
  <div class="inline-flex w-full flex-col items-stretch justify-center gap-2 px-4 md:flex-row xl:px-0">
    <button (click)="action({type: 'login'})" class="btn md:btn-lg md:btn-wide group px-12">
      Đăng nhập
    </button>
    <button (click)="action({type: 'register'})" class="btn btn-neutral md:btn-lg md:btn-wide group px-12">
      đăng ký
    </button>
  </div>
</div>
}

<ng-template #bottomNavigationTemplate>
  <div
    class="sticky bottom-0 btm-nav btm-nav-xs md:btm-nav-sm w-full mx-auto bg-base-200 md:px-[25vw] rounded-t-3xl">
    @for (item of getBottomItems(); track $index) {
    <button (click)="action({type:'bottom-navbar-select', data: item})" class="px-0 md:px-4"
      [ngClass]="currentURL() === item.url ? 'active' : ''">
      @if(item?.icon) {
      <lucide-icon [name]="item.icon"></lucide-icon>
      }
      <span class="btm-nav-label">{{item.title}}</span>
    </button>
    }
  </div>
</ng-template>


<ng-template #tabListDesktopTemplate>
  <div role="tablist" class="tabs tabs-bordered">
    @for (item of tabListItems; track $index) {
    <a role="tab" class="tab w-max" [ngClass]="{'tab-active': currentURL()?.startsWith(item.url)}"
      (click)="action({type: 'select-tab', data: item })">
      <span class="first-letter:uppercase">{{ item.title }}</span>
    </a>
    }
  </div>
</ng-template>


<ng-template #tabListMobileTemplate>
  <div class="h-full md:hidden dropdown dropdown-bottom">
    <div tabindex="0" role="button" class="btn btn-outline btn-xs m-1 w-max"
      (click)="toggleElementOnClick($event)">
      <span class="first-letter:uppercase"> {{ tabListItems[activeTutorialTab()].title }} </span>
      <lucide-icon [name]="icons.ChevronDown"></lucide-icon>
    </div>
    <ul tabindex="0" class="dropdown-content menu bg-base-100 rounded-box z-[1] w-48 md:w-64 lg:w-96 p-2 shadow">
      @for (item of tabListItems; track $index) {
      <li [ngClass]="{'tab-active': $index === activeTutorialTab()}"
        (click)="action({type: 'select-tab', data: item });clickInsideBlur();">
        <a class="first-letter:uppercase"><span class="first-letter:uppercase">{{ item.title }}</span></a>
      </li>
      }
    </ul>
  </div>
</ng-template>