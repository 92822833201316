// file-manager.service.ts

import { Injectable } from '@angular/core';
import { directoryOpen, supported, fileSave, FileWithDirectoryAndFileHandle, fileOpen } from 'browser-fs-access';

@Injectable({
    providedIn: 'root'
})
export class FileManagerService {
    private isFileSystemAccessSupported: boolean;

    constructor() {
        this.isFileSystemAccessSupported = supported;
    }

    async openFile(node: TreeNode) {
        if (node.type !== 'file') {
            console.error('Selected node is not a file');
            return;
        }

        try {
            if (!node.content) {
                node.content = await this.readFileContent(node.blob!);
                return node.content;
            }

            console.log('Opened file:', node.name);
            console.log('File content:', node.content);

            // Here you can perform additional actions with the file content
            if (node.name.endsWith('.md')) {
                console.log('Markdown content:', node.content);
                // Process markdown content if needed
            }
        } catch (error) {
            console.error('Error reading file content:', error);
        }

    }

    private async readFileContent(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target?.result as string);
            reader.onerror = (e) => reject(e);
            reader.readAsText(blob);
        });
    }

    async openFolder(): Promise<TreeNode[]> {
        try {
            const options = {
                recursive: true,
                mode: 'read' as const,
            };

            const blobs = await directoryOpen(options);
            if (Array.isArray(blobs)) {
                return this.processBlobs(blobs as FileWithDirectoryAndFileHandle[]);
            } else {
                console.error('Unexpected result from directoryOpen');
                return [];
            }
        } catch (error) {
            console.error('Error opening folder:', error);
            return [];
        }
    }


    private processBlobs(blobs: File[]): TreeNode[] {
        const result: TreeNode[] = [];
        const folderMap = new Map<string, TreeNode>();

        blobs.forEach(blob => {
            const pathParts = blob.webkitRelativePath.split('/');
            let currentPath = '';
            let currentNode: TreeNode | undefined;

            pathParts.forEach((part, index) => {
                currentPath += (index > 0 ? '/' : '') + part;

                if (!folderMap.has(currentPath)) {
                    const newNode: TreeNode = {
                        name: part,
                        type: index === pathParts.length - 1 ? 'file' : 'folder',
                        path: currentPath,
                        children: [],
                    };

                    if (newNode.type === 'file') {
                        newNode.size = blob.size;
                        newNode.lastModified = new Date(blob.lastModified);
                        newNode.blob = blob;
                    }

                    folderMap.set(currentPath, newNode);

                    if (currentNode) {
                        currentNode.children!.push(newNode);
                    } else {
                        result.push(newNode);
                    }
                }

                currentNode = folderMap.get(currentPath);
            });
        });

        return result;
    }

    async saveFile(blob: Blob, fileName: string): Promise<void> {
        try {
            await fileSave(blob, {
                fileName: fileName,
                extensions: ['.txt'], // Adjust this based on your file type
            });
        } catch (error) {
            console.error('Error saving file:', error);
        }
    }
}

export interface TreeNode {
    name: string;
    type: 'folder' | 'file';
    path: string;
    children?: TreeNode[];
    size?: number;
    lastModified?: Date;
    blob?: Blob;
    content?: any;
}