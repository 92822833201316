import {  inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/frontend-core';
import {  PlausibleService} from '@app/frontend-core';
import {  LinkOrRegisterMutationVariables, LoginMutation } from '@app/generated';
import { Location } from '@angular/common';
import { LinkOrRegisterMutation } from '@app/generated'


@Injectable({ providedIn: 'root' })
export class PeopleHelpGear {
  snackbarService = inject(SnackbarService);
  router = inject(Router);
  
  location = inject(Location);
  plausibleService = inject(PlausibleService);
  
  handleError(error: Error) {
    const code = error.message.split(':')[0];
    switch (code) {
      case 'WEAKP':
        this.snackbarService.showError('Mật khẩu quá yếu, cần lớn hơn 8 ký tự');
        break;
      case 'LOCKD':
        this.snackbarService.showError('Quá nhiều lần reset, thử lại sau 6 tiếng');
        break;
      case 'CREDS':
        this.snackbarService.showError('Tên đăng nhập / mật khẩu không đúng');
        break;
      case 'TAKEN':
        this.snackbarService.showError('Tài khoản khác đã liên kết với hồ sơ này');
        break;
      case 'EMTKN':
        this.snackbarService.showError('Tài khoản khác đã liên kết với email này');
        break;
      case 'RFTE':
        this.snackbarService.showError('Xác thực hết hạn, đăng nhập lại bạn nhé!');
        break;
      default:
        this.snackbarService.showError('Có lỗi khi đăng nhập đăng ký');
        break;
    }
  }

  handleRegisterOfSocial(variables: LinkOrRegisterMutationVariables, data?: LinkOrRegisterMutation['linkOrRegister'] | null,) {
    console.log('handleRegister', data, variables);
    if (data && data.accessToken && data.refreshToken) {
      this.handleLogin(data);
      if (data.note.isNewUser && variables.profile.provider) {
        this.plausibleService.trackEvent('Signup', { props: { provider: variables.profile.provider } });
      }
    } else {
      this.snackbarService.showError(`Lỗi không đọc được xác thực`);
    }
  }

 
  
  handleLogin(loginPayload?: LinkOrRegisterMutation['linkOrRegister'] | LoginMutation['login']) {
    console.log('handleLogin', loginPayload);
    if (loginPayload) {
      localStorage.setItem('accessToken', loginPayload.accessToken);
      localStorage.setItem('refreshToken', loginPayload.refreshToken);
      localStorage.setItem('sessionId', loginPayload.sessionId);
    }
    this.snackbarService.showSuccess(`Chào mừng bạn`);
    //  this.router.navigateByUrl('/');  despricate, handle at login page
  }

 

  handleLogout(notify = true) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('sessionId');
    if (notify) {
      this.snackbarService.showSuccess('Tạm biệt');
    }
  }

}