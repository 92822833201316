import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'dialog-welcome-trial',
    template: `
    <div class="prose py-6 px-4">
        <h3> Chào mừng bạn đến với LOSA AI! </h3>
        <p>Bạn đã được TẶNG gói dùng thử trị giá 50.000 VND có hiệu lực trong <strong>7 ngày</strong> </p>
        <p>Sau khi hết hạn dùng thử, bạn có thể lựa chọn tiếp tục dùng miễn phí hoặc nâng cấp gói sau. <p>
    </div>
    `,
})

export class WelcomeTrialDialog {
}