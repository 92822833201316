import { CommonModule } from "@angular/common";
import { Component, inject, input, TemplateRef } from "@angular/core";
import { ChatterNavbarState } from "../chatter-navbar.state";
import { ChatLeftMenuComponent } from "../navbar/chat-left-bar-menu.component";
import { RouterOutlet } from "@angular/router";
import { SidebarOrgan } from "../sidebar/sidebar.organ";
import { ActionEvent } from "@app/frontend-core";
import { FileManagerComponent } from "frontend-ui/src/components/file-manager.component";

@Component({
  selector: 'layout-editor',
  standalone: true,
  imports: [
    CommonModule, SidebarOrgan, RouterOutlet, FileManagerComponent
  ],
  template: `
<div class="drawer h-full" [ngClass]="chatterNavbarState.chatLeftBarsMenu() ? 'md:drawer-open' : ''">
  <input title="drawer" id="left-chat-drawer" type="checkbox" class="drawer-toggle"
    [attr.checked]="chatterNavbarState.chatLeftBarsMenu() ? '' : null" />

  <div class="drawer-content px-2 flex flex-col">
    <ng-container [ngTemplateOutlet]="topNavbarTemplate() ?? defaultTemplate"></ng-container>

    <div class="flex-1 relative mx-auto w-full md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg">
      <router-outlet></router-outlet>
    </div>

  </div>
  <div class="drawer-side">
    <label (click)="chatterNavbarState.chatLeftBarsMenu.set(false)" aria-label="close sidebar" class="drawer-overlay"></label>
    <div class="flex bg-base-300 h-full w-[85vw] md:w-[260px] max-h-dvh relative">
      <organ-sidebar [sidebarPosition]="'left'"
      class="h-full"
      (action)="action({data: $event, type:'toggle-navigation'})"
      [dataMenus]="chatterNavbarState.editorMenuLeft()"></organ-sidebar>
      <app-file-manager class="h-full w-full"></app-file-manager>
    </div>

  </div>
</div>
<ng-template #defaultTemplate></ng-template>

    `,
  styles: [`:host { display: block}`],
})
export class EditorLayout {
  currentURL = input<string>('/');
  topNavbarTemplate = input<TemplateRef<any>>();
  bottomNavigationTemplate = input<TemplateRef<any>>();
  chatterNavbarState = inject(ChatterNavbarState);

  tree = [];

  action(event: ActionEvent) {

  }
}