
import { Clover, File, Home, MessageCircle, MessageCircleCode } from 'lucide-angular';
import { DropdownItemBase } from '../../global';
export interface DataMenuInterface extends DropdownItemBase {
  routerId: string;
  linkTo?: string;
  color?: string;
  bg?: string;
  isActive?: boolean;
  type?: 'modifer' | 'navigation' | 'tab';
}

export const DATA_NAVIGATION_MENU: DataMenuInterface[] = [

  // {
  //   routerId: 'file',
  //   title: 'Soạn thảo',
  //   description: 'Trình soạn thảo văn bản, quản lý tài liệu, viết với AI',
  //   linkTo: '/editor',
  //   icon: faFile,
  //   color: 'text-primary',
  //   bg: 'bg-primary',
  //   type: 'navigation',
  // },
  {
    routerId: 'chat',
    title: 'Trò chuyện',
    description: 'hỏi đáp, tìm kiếm thông tin',
    linkTo: '/c',
    icon: MessageCircle,
    color: 'text-primary',
    bg: 'bg-primary',
  },
  {
    routerId: 'welcome',
    title: 'Chào mừng',
    description: '(tính năng đang phát triển - sẽ ra mắt trong tháng này)',
    linkTo: '/welcome',
    icon: Home,
    color: 'text-primary',
    bg: 'bg-primary',
  },
  // {
  //   routerId: 'https://losa.vn',
  //   title: 'Trang chủ Losa.vn ',
  //   description: '(tính năng đang phát triển - sẽ ra mắt trong tháng này)',
  //   linkTo: 'https://losa.vn',
  //   icon: faExternalLink,
  //   color: 'text-primary',
  //   bg: 'bg-primary',
  //   type: 'navigation',
  //   // isActive: true,
  // },

]

export const DATA_MODIFER_MENU: DataMenuInterface[] = [
  {
    routerId: 'double-editor',
    title: 's.thảo đôi',
    description: '',
    linkTo: '/editor',
    icon: Clover,
    color: 'text-primary',
    bg: 'bg-primary',
    type: 'modifer',
  },
  {
    routerId: 'bottom-streaming-result',
    title: 'Bảng phụ',
    description: '',
    linkTo: '/editor',
    icon: Clover,
    color: 'text-primary',
    bg: 'bg-primary',
    type: 'modifer',
  },

]

export const EDITOR_MENU_LEFT: DataMenuInterface[] = [

  {
    routerId: 'file',
    title: 'file',
    description: '(tính năng đang phát triển - sẽ ra mắt trong tháng này)',
    linkTo: '/editor',
    icon: File,
    color: 'text-primary',
    bg: 'bg-primary',
    type: 'tab',
  },
  {
    routerId: 'left-chat',
    title: 'chat',
    description: '(tính năng đang phát triển - sẽ ra mắt trong tháng này)',
    linkTo: '/editor',
    icon: MessageCircleCode,
    color: 'text-primary',
    bg: 'bg-primary',
    type: 'tab',
    // isActive: true,
  },

]

// export const DATA_MENU_SETTING: DataMenuInterface[] = [
//   {
//     routerId: 'organization',
//     title: 'Tổ chức',
//     icon: faSitemap,
//     linkTo: '/settings/organization'
//   },
//   {
//     routerId: 'shop',
//     title: 'Cửa hàng',
//     icon: faStore,
//     linkTo: '/settings/shop'
//   },
//   {
//     routerId: 'staff',
//     title: 'Nhân viên',
//     icon: faUsersGear,
//     linkTo: '/settings/staff'
//   },
//   {
//     routerId: 'user',
//     title: 'Người dùng',
//     icon: faUser,
//     linkTo: '/settings/user'
//   },
// ]
