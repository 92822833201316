import { Injectable, inject, signal } from '@angular/core';
import { SnackbarService, graphQLClient } from '@app/frontend-core';
import { AppMessageConversation, GetConversationsDocument, GetConversationsQueryVariables, GetMessagesDocument, GetMessagesQueryVariables, NewConversationDocument, NewConversationMutationVariables, NewMessageDocument, NewMessageMutationVariables, NewThreadDocument, NewThreadMutationVariables, RemoveConversationDocument, RemoveConversationMutationVariables, UpdateConversationDocument } from '@app/generated';
import { injectMutation, injectQuery, injectQueryClient } from '@tanstack/angular-query-experimental';
import { SubscriptionGear } from 'frontend-state/src/people';
import { ModelGear } from './model.gear';
import { CharacterGear } from './character.gear';
import { aiStreaming } from 'frontend-ui/src/chatter-dashboard/pages/ai.service';

export interface ConversationMessage {
    senderUserId: string;
    messageId: string;
    content: string;
    displayName: string;
    messageCreatedAt?: string;
}

@Injectable({ providedIn: 'root' })
export class MessageGear {
    snackbarService = inject(SnackbarService);
    activeConversationId = signal<string | null | undefined>(null);
    queryClient = injectQueryClient();
    currentConversationUserIds = signal<string[]>([]);
    subscriptionGear = inject(SubscriptionGear);
    homePageUserInput = signal<string | null>(null);
    modelGear = inject(ModelGear);
    characterGear = inject(CharacterGear);

    async setNewTitleOfConversationInStream(chatlog = 'user: hello\n model: Hi, how I can help?') {
        const activeConversation = this.getActiveConversation();
        const old = this.currentConversationsGet() || [];
        const newData = [...old];
        if (activeConversation) {
            const streamingResult = await aiStreaming('', {
                model_id: this.modelGear.aiModelInformation()!.model_id,
                subscription_id: this.subscriptionGear.activeSubscriptionId(),
                // systemPrompt: `write a short, concise title that summarizes the topic of the conversation from 3 to 9 words in chatlog language:\n ${chatlog}`,
             
                // https://github.com/cohere-ai/cohere-toolkit/blob/ca5ab66beb8edb0064c99b66dc6348282cd252bd/src/backend/services/conversation.py#L19
                systemPrompt: `
# TASK
Given the following conversation history, write a short title that summarizes the topic of the conversation in chatlog language. Be concise and respond with just the title which is written in the given Language.

## START CHATLOG
${chatlog}
## END CHATLOG

# TITLE
`,

                // https://github.com/danny-avila/LibreChat/blob/ea5140f/api/app/clients/prompts/titlePrompts.js
                // systemPrompt: `Write a concise title for this conversation in the given language. Title in 5 Words or Less. No Punctuation or Quotation or Double Quotes. Must be in Title Case, written in the given Language. \n${chatlog}`,
                max_tokens: 10,
                
                body: {},
            }, (snapshot, delta) => {
                newData[old.indexOf(activeConversation)] = {
                    ...activeConversation,
                    conversationTitle: snapshot
                };
                this.currentConversationsUpdate(newData);
            });

            const { appMessageUpdateConversation } = await graphQLClient.request(UpdateConversationDocument, {
                conversationId: activeConversation.conversationId,
                conversationTitle: streamingResult.snapshot,
                conversationUserIds: null,
            });
        }
    }

    getConversations() {
        return injectQuery(() => ({
            queryKey: ['memberConversations', {
                pConversationUserIds: this.currentConversationUserIds()
            } as GetConversationsQueryVariables],
            queryFn: async (queryKey) => {
                const { appMessageGetConversations } = await graphQLClient.request(GetConversationsDocument, {
                    pConversationUserIds: this.currentConversationUserIds(),
                    pSearch: '',
                });
                return appMessageGetConversations?.nodes || [];
                // return [...(appMessageGetConversations?.nodes || []), ...(appMessageGetConversations?.nodes || [])] || [];
            }
        }));
    }

    currentConversationsUpdate(newData: AppMessageConversation[]) {
        // Optimistically update to the new value
        this.queryClient.setQueryData(['memberConversations', {
            pConversationUserIds: this.currentConversationUserIds()
        } as GetConversationsQueryVariables], (old) => newData);
    }

    currentConversationsGet(): AppMessageConversation[] | undefined {
        return this.queryClient.getQueryData(['memberConversations', {
            pConversationUserIds: this.currentConversationUserIds()
        } as GetConversationsQueryVariables]);
    }

    getActiveConversation() {
        const appMessageConversations = this.currentConversationsGet();
        const activeConversation = appMessageConversations?.find((conversation) => conversation?.conversationId === this.activeConversationId())
        return activeConversation;
    }

    getActiveConversationIndex() {
        const appMessageConversations = this.currentConversationsGet();
        const activeIndex = appMessageConversations?.findIndex((conversation) => conversation?.conversationId === this.activeConversationId())
        return activeIndex;
    }

    // conversationMessagesKey() {
    //     return ['getMessages', {
    //         pConversationId: this.activeConversationId()!
    //     } as GetMessagesQueryVariables];
    // }

    queryGetMessages = injectQuery(() => ({
        queryKey: ['getMessages', {
            pConversationId: this.activeConversationId()!
        } as GetMessagesQueryVariables],
        queryFn: async (queryKey): Promise<ConversationMessage[]> => {
            const pConversationId = (queryKey.queryKey[1] as GetMessagesQueryVariables).pConversationId;
            if (!pConversationId) return [];
            const { appMessageGetMessages } = await graphQLClient.request(GetMessagesDocument, {
                pConversationId,
                pSearch: '',
            });
            // console.log('result', appMessageGetMessages?.nodes);
            const messages = (appMessageGetMessages?.nodes ?? [])
                .filter(m => !!m)
                .map(chat => ({
                    senderUserId: chat.messageMetadata?.senderUserId!,
                    messageId: chat.message?.messageId!,
                    content: chat.message?.content!,
                    displayName: chat.sender?.displayName!,
                    messageCreatedAt: chat.message?.messageCreatedAt!,
                }))
                
            return messages || [];
        },
        enabled: !!this.activeConversationId()
    }));

    newMessageMutation = injectMutation(() => ({
        mutationFn: (thisMess: NewMessageMutationVariables & {displayName: string}) => {
            this.updateMessageState(thisMess);
            return graphQLClient.request(NewMessageDocument, thisMess);
        },
        onError: (error, variables, context) => {
            // this.peopleHelpGear.handleError(error);
        },
        onSuccess: (data, variables, context) => {
            console.log('data', data);
            
        },
    }));

    updateMessageState(thisMessage: NewMessageMutationVariables & { displayName?: string | null }) {
        this.queryClient.setQueryData(['getMessages', {
            pConversationId: this.activeConversationId()!
        } as GetMessagesQueryVariables], (old: ConversationMessage[]) => {
            const existingMessageIndex = old.findIndex(msg => msg.messageId === thisMessage.messageId);
            if (existingMessageIndex !== -1) {
                // If the message exists, update it
                const updatedMessages = [...old];
                updatedMessages[existingMessageIndex] = {
                    senderUserId: thisMessage.senderUserId!,
                    messageId: thisMessage.messageId!,
                    content: thisMessage.content!,
                    displayName: thisMessage.displayName!,
                    messageCreatedAt: old[existingMessageIndex].messageCreatedAt,
                };
                return updatedMessages;
            } else {
                // If the message doesn't exist, add it to the end
                return [...old, {
                    senderUserId: thisMessage.senderUserId,
                    messageId: thisMessage.messageId!,
                    content: thisMessage.content!,
                    displayName: thisMessage.displayName,
                    messageCreatedAt: new Date().toISOString(),
                }];
            }
        })
    }

    newConversationMutation = injectMutation(() => ({
        mutationFn: (v: NewConversationMutationVariables) => graphQLClient.request(NewConversationDocument, v),
        onError: (error, variables, context) => {
            // this.peopleHelpGear.handleError(error);
        },
        onSuccess: (data, variables, context) => {
            console.log('data', data);
            return data.appMessageNewConversation;
           
        },
        onSettled: async () => {
            return await this.queryClient.invalidateQueries({ queryKey: ['memberConversations'] })
        },
    }));

    removeConversation = injectMutation(() => ({
        mutationFn: (v: RemoveConversationMutationVariables) => graphQLClient.request(RemoveConversationDocument, v),
        onError: (error, variables, context) => {
            // this.peopleHelpGear.handleError(error);
        },
        onSuccess: (data, variables, context) => {
            console.log('data', data);
            this.snackbarService.showSuccess('Đã xoá');
         
        },
        onSettled: async () => {
            await this.queryClient.invalidateQueries({ queryKey: ['memberConversations'] })
            return await this.queryClient.invalidateQueries({ queryKey: ['messagesByConversationId'] })
        },
    }));


    newThreadMutation = injectMutation(() => ({
        mutationFn: (v: NewThreadMutationVariables) => graphQLClient.request(NewThreadDocument, v),
        onError: (error, variables, context) => {
            // this.peopleHelpGear.handleError(error);
        },
        onSuccess: (data, variables, context) => {
            console.log('data', data);
       
        },
    }));


    remainStatus = signal({
        level10: false,
        level1: false,
        level0: false,
        currentPercent: 100,
    });

    checkRemainStatus(subscription: { subscription_amount: number; subscription_balance: number; }) {
        const remainPercent = subscription.subscription_balance / subscription.subscription_amount * 100;

        if (remainPercent < 0 && this.remainStatus().level0 === false) {
            this.snackbarService.showWarning('Dung lượng còn 0%');
            this.remainStatus.set({
                currentPercent: remainPercent,
                level10: true,
                level1: true,
                level0: true,
            });
        } else if (remainPercent < 10 && this.remainStatus().level10 === false) {
            this.snackbarService.showWarning('Dung lượng còn dưới 10%');
            this.remainStatus.set({
                currentPercent: remainPercent,
                level10: true,
                level1: false,
                level0: false,
            });
        } else if (remainPercent < 1 && this.remainStatus().level1 === false) {
            this.snackbarService.showWarning('Dung lượng còn dưới 1%');
            this.remainStatus.set({
                currentPercent: remainPercent,
                level10: true,
                level1: true,
                level0: false,
            });
        } else {
            this.remainStatus.set({
                currentPercent: remainPercent,
                level10: false,
                level1: false,
                level0: false,
            });
        }
    }

}