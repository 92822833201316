import { ExchangeTokenDocument } from '@app/generated';
import * as jose from 'jose';
// console.warn('graphql request coming... use-graphql.helper.ts');
import {
  GraphQLClient,
  RequestMiddleware,
  ResponseMiddleware,
  request,
} from 'graphql-request';
import { Subject } from 'rxjs';

let isExchangingNewToken = false;

export const graphqlErrorShowOnSnackbarServiceForClientUser$ =
  new Subject<string>();

const requestMiddleware: RequestMiddleware = async (request) => {
  const token = localStorage.getItem('accessToken')?.length
    ? `Bearer ${localStorage.getItem('accessToken')}`
    : undefined;
  if (isExchangingNewToken) {
    console.warn('BUSY: is exchanging you new token....');
  } else {
    await checkAccessTokenExpired();
  }
  return {
    ...request,
    headers: { ...request.headers, ...(token ? { Authorization: token } : {}) },
  };
};

const responseMiddleware: ResponseMiddleware = (response) => {
  response = (response as any).response ? (response as any).response : response;
  const errors =
    (response as any).errors || (response as any)?.response?.errors;
  // console.log('??errors', errors, response instanceof Error, response)
  if (errors?.length) {
    const errMess = `GraphQL client error: ${errors.map((error: any) => error.message).join(`, `)}`;
    console.error(errMess);
    graphqlErrorShowOnSnackbarServiceForClientUser$.next(errMess);
  }

  // console.debug('response JSON.parse?', Object.keys(response), response);
  // this not happen since it will break out app
  // if ((response as any).status === 401 && (response as any).message.startsWith('FTAT: Failed to authenticate token')) {
  //   // console.log('token expired')    
  //   // graphqlErrorShowOnSnackbarServiceForClientUser$.next('Phiên làm việc hết hạn');
  //   console.warn('Đang trao đổi token');
     
  // }

};

const endpoint = '/v1/graphql';

export const graphQLClient = new GraphQLClient(endpoint, {
  requestMiddleware,
  responseMiddleware,
  // headers: {
  //   ...(localStorage.getItem('accessToken') ? { authorization: `Bearer ${localStorage.getItem('accessToken') }` } : {}),
  // },
});



export async function checkAccessTokenExpired() {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  if (accessToken?.length && refreshToken?.length) {
    const decodedToken = jose.decodeJwt(accessToken);
    if (decodedToken && decodedToken.exp && typeof decodedToken === 'object' && 'exp' in decodedToken) {
      const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
      const currentTime = Date.now();
      const threshold = 10 * 60 * 1000; // 10 * 60 seconds = 10 mins threshold
      // https://g.co/bard/share/ecbd1fac88d6
      const closeToExpiring = expirationTime - currentTime <= threshold;

      // console.log('expirationTime', new Date(expirationTime), 'currentTime', new Date(currentTime), expirationTime - currentTime, '<=', threshold, 'exprire in', Math.round((expirationTime - currentTime) / 1000 / 60) + ' minutes');

      if (closeToExpiring) {
        console.warn('you need to exchange now!!')
        return exchangeTokeToServer();
        // return false;
        // Placeholder for refresh token process
        // refreshAccessToken(); // Uncomment and define this function to refresh the access token
      } else {
        // console.warn('your token is fine', new Date(expirationTime), new Date(currentTime), expirationTime - currentTime, '<=', threshold, 'exprire in', Math.round((expirationTime - currentTime) / 1000 / 60) + ' minutes');
        return true;
      }
    } else {
      return false;
    }
  } else {
    return null;
  }
}

let isExchanging = false;

export async function exchangeTokeToServer() {
  let refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) throw new Error('no exchange token');
  try {
    const { exchangeToken } = await request(endpoint,ExchangeTokenDocument,{ refreshToken });
    if (exchangeToken?.accessToken && exchangeToken?.refreshToken) {
      localStorage.setItem('accessToken', exchangeToken.accessToken);
      localStorage.setItem('accessToken', exchangeToken.accessToken);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    throw new Error(error);
  }
  // Placeholder for refresh token process
  // refreshAccessToken(); // Uncomment and define this function to refresh the access token
}

/** this is quick query data */
// export function qQuery<TResult, TVariables>(
//   document: TypedDocumentNode<TResult, TVariables>,
//   ...[variables]: TVariables extends Record<string, never> ? [] : [TVariables]
// ): CreateQueryResult<TResult> {
//   return injectQuery(() => ({
//     queryKey: [(document.definitions[0] as any).name.value, variables],
//     queryFn: async ({ queryKey }: any) =>
//       graphQLClient.request(
//         document,
//         queryKey[1] ? queryKey[1] : undefined,
//         // variables ?? undefined
//       ),
//   }));
// }
