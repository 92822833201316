import { Routes } from '@angular/router';
import { ProfileRouting } from 'frontend-ui/src/people';

export const UserRouting: Routes = [
  {
    path: 'user',
    children: [
      {
        path: 'email',
        loadComponent: () =>
          (window as any).isMobile
            ? import('./email-mobile/email-mobile.page').then(
                (m) => m.EmailMobilePage,
              )
            : import('./email-desktop/email-desktop.page').then(
                (m) => m.EmailDesktopPage,
              ),
      },
      {
        path: 'passphrase',
        loadComponent: () =>
          (window as any).isMobile
            ? import('./passphrase-mobile/passphrase-mobile.page').then(
                (m) => m.PassphraseMobilePage,
              )
            : import('./passphrase-desktop/passphrase-desktop.page').then(
                (m) => m.PassphraseDesktopPage,
              ),
      },
      {
        path: 'linked-accounts',
        loadComponent: () =>
          (window as any).isMobile
            ? import(
                './linked-accounts-mobile/linked-accounts-mobile.page'
              ).then((m) => m.LinkedAccountsMobilePage)
            : import(
                './linked-accounts-desktop/linked-accounts-desktop.page'
              ).then((m) => m.LinkedAccountsDesktopPage),
      },
      {
        path: 'delete-account',
        loadComponent: () =>
          (window as any).isMobile
            ? import('./delete-account-mobile/delete-account-mobile.page').then(
                (m) => m.DeleteAccountMobilePage,
              )
            : import(
                './delete-account-desktop/delete-account-desktop.page'
              ).then((m) => m.DeleteAccountDesktopPage),
      },
      ...ProfileRouting,
    ],
  },
];
