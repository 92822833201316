<h1
  class="py-2 px-2 text-xl md:text-2xl text-gray-800 font-bold flex justify-center border-b w-full"
>
  <ng-content select="[dialog-title]"></ng-content>
</h1>
<div class="flex flex-col h-full pb-3 md:pb-8 mb-8">
  <ng-content></ng-content>
</div>
<div [hidden]="noAction" class="border-t absolute left-0 bottom-0 w-full">
  <ng-content select="[dialog-actions]"></ng-content>

  <div class="flex py-4 flex-row justify-end px-8 bg-base-100 w-full">
    <button
      *ngIf="confirm"
      (click)="confirmEvent.emit($event)"
      class="border bg-primary  hover:scale-110 px-4 py-1 rounded-md mx-3 border-primary"
    >
      {{ confirm }}
    </button>

    <button
      (click)="cancelEvent.emit($event); dialogRef?.close()"
      class="border  hover:scale-110 text-primary px-4 py-1 rounded-md mx-3 border-primary"
    >
      {{ cancel }}
    </button>
  </div>
</div>
