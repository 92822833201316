import { Injectable, computed, inject, signal } from '@angular/core';
import { DialogService } from '@ngneat/dialog';
import { PeopleGear } from './people.gear';
import { DialogsPeople, EndTrialDialog, ManageSubscriptionDialog, WelcomeTrialDialog } from '@app/frontend-ui';
import { SnackbarService, graphQLClient } from '@app/frontend-core';
import { injectQuery, injectMutation } from '@tanstack/angular-query-experimental';
import { MySubscriptionDocument, MySubscriptionQuery, ToogleTrialSubscriptionDocument } from '@app/generated';
import { DepositPaymentBody } from 'configs/global';


@Injectable({ providedIn: 'root' })
export class SubscriptionGear {
    peopleGear = inject(PeopleGear);
    dialogsPeople = inject(DialogsPeople);
    dialogService = inject(DialogService);
    snackbarService = inject(SnackbarService);
    activeSubscriptionId = signal('');
    
    mySubscriptionQuery = injectQuery(() => ({
        queryKey: ['mySubscription'],
        queryFn: async ({ queryKey }) => {
            const result = await graphQLClient.request(
                MySubscriptionDocument,
            );
            this.checkSubscription(result);
            return result;
        },
        enabled: !!this.peopleGear.myProfile()?.userId,
    }));

    async checkSubscription(mySubscriptionQuery: MySubscriptionQuery) {
        this.subscriptions.set(mySubscriptionQuery?.appBusinessMySubscription?.nodes ?? []);

        const noSubscription = this.subscriptions().length === 0;
        if (noSubscription) {
            return this.activateTrial();
        };

        const activeSubscription = this.subscriptions()[0]!;
        switch (activeSubscription.subscriptionStatus) {
            case 'active':
                this.activeSubscriptionId.set(activeSubscription.subscriptionId);
                this.currentSubscription.set(activeSubscription);
                return activeSubscription;

            default:

                this.openSubscriptionDialog();
                this.snackbarService.showError('Lỗi gói đăng ký chưa định dạng! ' + activeSubscription.subscriptionStatus);
                return null;
        }
    }

    
    subscriptions = signal<NonNullable<MySubscriptionQuery['appBusinessMySubscription']>['nodes']>([]);
    currentSubscription = signal<NonNullable<MySubscriptionQuery['appBusinessMySubscription']>['nodes'][0] | null>(null);

    currentSubscriptionName = computed(() => {
        const sub = this.currentSubscription();
        if (!sub) return 'chưa đăng ký';
        switch (sub.subscriptionType) {
            case 'free':
                return 'miễn phí';
            case 'paid':
                return 'trả phí';
            case 'trial':
                return 'dùng thử';
            default:
                return 'không biết';
        }
    });

    depositStatus = injectMutation(() => ({
        mutationFn: async ({
            deposit_code,
            product_quantity,
            product_unit_price,
        }: DepositPaymentBody) => {
            const response = await fetch('/v1/api/deposit-payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({
                    deposit_code,
                    product_quantity,
                    product_unit_price,
                }),
            });
            return response.json();
        },
        networkMode: 'online',
    }));

    orderEstimate = injectMutation(() => ({
        mutationFn: async ({
            deposit_code,
            product_quantity,
            product_unit_price,
        }: DepositPaymentBody) => {
            const response = await fetch('/v1/api/order-estimate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({
                    deposit_code,
                    product_quantity,
                    product_unit_price,
                }),
            });
            return response.json();
        },
    }));

    activeStartTrial = injectMutation(() => ({
        mutationFn: async (variables) => {
            return await graphQLClient.request(
                ToogleTrialSubscriptionDocument,
                {
                    pTrialStatus: 'trial-start'
                }
            );
        },
        onError: (error, variables, context) => {
            this.snackbarService.showError(error.message);
        },
        onSuccess: (data, variables, context) => {
            this.peopleGear.myProfileQuery.refetch();
            this.mySubscriptionQuery.refetch();
            console.log('activeStartTrial data:', data, this.currentSubscription());
        }
    }));


    openSubscriptionDialog(activeSubscriptionId?: string) {
        const dialogRef = this.dialogService.open(ManageSubscriptionDialog);
        dialogRef.afterClosed$.subscribe((res: any) => {
            const newActive = res && res.activeSubscriptionId ? res.activeSubscriptionId : activeSubscriptionId;
            if (newActive) this.activeSubscriptionId.set(newActive)
        })

    }
    
    async activateTrial() {
        this.openWelcomeTrialDialog();
        this.activeStartTrial.mutate();

    }

    openWelcomeTrialDialog() {
        const dialog = this.dialogService.open(WelcomeTrialDialog);
    }
    openEndTrialDialog() {
        const dialog = this.dialogService.open(EndTrialDialog);
    }


}