import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class SeoService {
  default = {
    title: 'LOSA AI - thư ký AI trực tuyến đáng yêu',
    description:
      'AI Chat, AI tạo ảnh, AI viết giúp bạn tăng năng suất và hiệu quả làm việc',
    keywords: 'Losa AI, AI Chat, AI tạo ảnh, AI viết',
    image: '/frontend-assets/icon.webp',
    thumbnail: '/frontend-assets/icon.webp',
  };
  meta = inject(Meta);
  title = inject(Title);
  document = inject(DOCUMENT);

  constructor() {
    this.seoMeta({});
  }

  convertVietnameseToSlug(text: string) {
    text = text.toLowerCase();
    text = text.replace(/á|à|ả|ã|ạ|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
    text = text.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
    text = text.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
    text = text.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
    text = text.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
    text = text.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
    text = text.replace(/đ/gi, 'd');
    text = text.replace(/[^a-z0-9-\s]/g, '');
    text = text.replace(/(\s+)/g, '-');
    text = text.replace(/-+/g, '-');
    return text.trim();
  }

  async seoMeta(metatag: {
    url?: string;
    title?: string;
    description?: string;
    keywords?: string;
    image?: string;
    thumbnail?: string;
  }) {
    const url = metatag.url ?? this.document.location.href;
    const title =
      metatag.title ??
      this.document.querySelector('title')?.textContent ??
      this.default.title;
    const description =
      metatag.description ??
      this.document
        .querySelector('meta[name="description"]')
        ?.getAttribute('content') ??
      this.default.description;
    const keywords =
      metatag.keywords ??
      this.document
        .querySelector('meta[name="keywords"]')
        ?.getAttribute('content') ??
      this.default.keywords;
    const image =
      metatag.image ??
      this.document
        .querySelector('meta[name="image"]')
        ?.getAttribute('content') ??
      this.default.image;
    // const thumbnail = metatag.thumbnail ?? this.document.querySelector('meta[name="thumbnail"]')?.getAttribute('content') ?? this.default.thumbnail;
    const fullPathImage = image.startsWith(this.document.location.origin) ? image : this.document.location.origin + image;
    // console.log('image', {
    //   ['metatag.image']: metatag.image,
    //   ['meta[name="image"]']: this.document
    //     .querySelector('meta[name="image"]')
    //     ?.getAttribute('content'),
    //   ['this.default.image']: this.default.image,
    // });
    const tags: MetaDefinition[] = [
      { property: 'cannonical', content: url },
      { property: 'og:url', content: url },
      { property: 'og:type', content: 'article' },
      { property: 'article:author', content: 'Losa Team' },
      { property: 'article:section', content: 'blog' },
      { property: 'article:tag', content: keywords },
      { name: 'keywords', content: keywords },

      { name: 'title', content: title },
      { property: 'og:title', content: title },

      { name: 'description', content: description.slice(0, 150) },
      { name: 'og:description', content: description.slice(0, 150) },

      { property: 'og:image:alt', content: title },
      { name: 'thumbnail', content: fullPathImage },
      { name: 'image', content: fullPathImage },
      { property: 'og:image', content: fullPathImage },

      { name: 'robots', content: 'index, follow' },
      { name: 'language', content: 'vi_VN' },
      { name: 'audience', content: 'general' },
      { property: 'og:locale', content: 'vi' },
    ];
    this.title.setTitle(title ?? '');
    tags
      .filter((tag) => tag.content)
      .forEach((tag) => this.meta.updateTag(tag));
  }
}
