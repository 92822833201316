import { Directive, Injectable, inject } from '@angular/core';
import { DialogService } from '@ngneat/dialog';


@Injectable({ providedIn: 'root' })
@Directive()
export class DialogsPeople {
  dialogService = inject(DialogService);

  async openDialogLogin(redirect = false) {
    const { LoginPage } = await import('../pages/login/login.page');
    const loginDialog = this.dialogService.open(LoginPage, {
      id: 'loginDialog',
      data: {
        mode: 'login',
        redirect: false,
      },
      width: '90vw',
    });
  }

  async openDialogRegister() {
    const { RegisterPage } = await import('../pages/register/register.page');
    const registerDialog = this.dialogService.open(RegisterPage, {
      id: 'registerDialog',
      data: {
        register: 'register',
        redirect: false,
      },
      width: '90vw',
    });
  }

  async openDialogForgotPassword() {
    const { ForgotPasswordPage } = await import('../pages/forgot-password/forgot-password.page');
    const forgotPasswordDialog = this.dialogService.open(
      ForgotPasswordPage,
      {
        id: 'forgotPasswordDialog',
        data: {},
        width: '90vw',
      },
    );
  }

  async openDialogNewPassword() {
    const { NewPasswordPage } = await import('../pages/new-password/new-password.page');
    const newPasswordDialog = this.dialogService.open(NewPasswordPage, {
      id: 'newPasswordDialog',
      data: {},
      width: '90vw',
    });
  }

  async openDialogResetPassword() {
    const { ResetPasswordPage } = await import('../pages/reset-password/reset-password.page');
    const resetPasswordDialog = this.dialogService.open(ResetPasswordPage, {
      id: 'resetPasswordDialog',
      data: {},
      width: '90vw',
    });
  }
}
