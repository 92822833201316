import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-footer',
  templateUrl: 'landing-footer.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export class LandingFooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
